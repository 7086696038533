import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SupportConsoleRoutingModule } from './support-console.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { HandleUserAgentPipe } from 'src/app/shared/pipes/handle-user-agent.pipe';
import { UsersComponent } from './components/users/users.component';
import { UserDetailComponent } from './components/children-components/user-detail/user-detail.component';
import { ActionsDetailComponent } from './components/children-components/actions-detail/actions-detail.component';
import { ChangeLogComponent } from './components/modals/change-log/change-log.component';
import { LoginHistoryComponent } from './components/modals/login-history/login-history.component';
import { LoginHistoryDetailComponent } from './components/children-components/login-history-detail/login-history-detail.component';
import { OrganizationDetailComponent } from './components/children-components/organization-detail/organization-detail.component';
import { SubscriptionsDetailComponent } from './components/children-components/subscriptions-detail/subscriptions-detail.component';
import { ProvidersDetailComponent } from './components/children-components/providers-detail/providers-detail.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SmsSetupComponent } from './components/modals/sms-setup/sms-setup.component';
import { IdVerificationComponent } from './components/modals/sms-setup/components/id-verification/id-verification.component';
import { PhoneNumberVerificationComponent } from './components/modals/sms-setup/components/phone-number-verification/phone-number-verification.component';
import { UserAcknowledgementsComponent } from './components/modals/sms-setup/components/user-acknowledgements/user-acknowledgements.component';
import { SmsSetupSummaryComponent } from './components/modals/sms-setup/components/sms-setup-summary/sms-setup-summary.component';
import { NoPasteEventDirective } from 'src/app/shared/directives/no-paste-event.directive';
@NgModule({
  declarations: [
    ActionsDetailComponent,
    ChangeLogComponent,
    LoginHistoryComponent,
    LoginHistoryDetailComponent,
    OrganizationDetailComponent,
    ProvidersDetailComponent,
    SubscriptionsDetailComponent,
    UsersComponent,
    UserDetailComponent,
    HandleUserAgentPipe,
    SmsSetupComponent,
    IdVerificationComponent,
    PhoneNumberVerificationComponent,
    UserAcknowledgementsComponent,
    SmsSetupSummaryComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    SupportConsoleRoutingModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NoPasteEventDirective
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SupportConsoleModule { }
