import { gql } from 'apollo-angular';



export const DELETE_USER_CLAIM = gql`
  mutation deleteUserClaim($id: ID!) {
    deleteUserClaims(id: $id) {
      data {
        deleted
        __typename
      }
    }
  }
`;

export interface DeleteUserClaimResponse {
  deleteUserClaims: {
    data: DeleteUserClaimData;
  };
}

export interface DeleteUserClaimData {
  deleted: boolean;
  __typename: string;
}
