import { inject, Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { switchMap, take } from "rxjs";
import { AuthenticationService } from "src/app/features/authentication/services/authentication.service";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable()
export class EmailGraphqlInterceptor implements HttpInterceptor {
    private authenticacionService = inject(AuthenticationService);
    private oidcSecurityService = inject(OidcSecurityService);

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes(environment.graphql.email)) {
            return this.authenticacionService.isAuthenticated$.pipe(
                take(1),
                switchMap((isAuthenticated) => {
                if (isAuthenticated) {
                    return this.oidcSecurityService.getAccessToken().pipe(
                    switchMap((accessToken) => {
                        const modifiedRequest = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        });
                        return next.handle(modifiedRequest);
                    })
                    );
                } else {
                    console.error("Email Access Token is required but not present.");
                    return next.handle(request);
                }
                })
            );
        } else {
            return next.handle(request); // No modify other requests.
        }
    }
}
 