/**
 * Generates a formatted string representing the current date and time.
 *
 * @param {number} [timeOffset] - An optional time offset in minutes to adjust the current date and time.
 * @returns {string} - A string representing the formatted date and time in "YYYY-MM-DD HH:mm:ss" format.
 */
export const formatDateTimeToLocaleString = (timeOffset = 0) => {
    const date = new Date();
    date.setUTCMinutes(date.getUTCMinutes() + timeOffset);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


/**
 * Formats a date-time string to match "M/D/YYYY h:mm:ss A"
 *
 * @param {number} [timeOffset] - An optional time offset in minutes.
 * @returns {string} - The formatted date-time string.
 */
export const formatDateTimeToFortifyFormat = (timeOffset = 0): string => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + timeOffset);

    const month = date.getUTCMonth() + 1; // No leading zero
    const day = date.getUTCDate(); // No leading zero
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${amPm}`;
};

