import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { SmsSetupService } from "../../support-console/services/sms-setup.service";
import { AuthenticationService } from "../services/authentication.service";
import { environment } from "src/environments/environment";
import { UserPortalDialogService } from "src/app/shared/services/dialog/user-portal-dialog.service";
import { UserClaim, UserSmsClaimValue } from "../../support-console/graphql/mutations/get-claim-date-expiration-by-user-sms.graphql";
import { formatDateTimeToFortifyFormat } from "src/app/shared/helpers";


export const SmsServiceVerificationGuard: CanActivateFn = async () => {
  const _smsSetupService = inject(SmsSetupService);
  const _authenticationService = inject(AuthenticationService);
  const _userPortalDialogService = inject(UserPortalDialogService);

  const userTokenClaims: UserClaim = await _smsSetupService.getUserClaimByName(
    _authenticationService.userId.value,
    'TwoFactorType'
  );


  if (!userTokenClaims || userTokenClaims.claimValue !== 'SMS') {
    return true;
  }

  const isMessageServiceEnabled: UserClaim = await _smsSetupService.getUserClaimByName(
    _authenticationService.userId.value,
    'PrimarySmsNumber'
  );

  if (!isMessageServiceEnabled) {
    return true;
  }

  const claimValue: UserSmsClaimValue = JSON.parse(isMessageServiceEnabled.claimValue) as UserSmsClaimValue;


  if (userTokenClaims.claimValue === 'SMS' && claimValue.enabled) {
    const expirationDate = new Date(claimValue.deActivationDate);
    const currentDate = new Date(formatDateTimeToFortifyFormat(0));

    if (expirationDate < currentDate) {
      //Log for debugging purposes in browser console.
      console.log('SMS Service expired');
      return new Promise<boolean>((resolve) => {
        let countdown = 10;

        const dialogRef = _userPortalDialogService.userPortalDialog(
          'Security Update Required',
          `<p>Your SMS verification service has expired. To continue please enable the
            <strong>Authenticator App</strong>. You will be redirected in ${countdown} seconds...</p>`,
          null,
          null
        );

        // Update countdown message every second
        const interval = setInterval(() => {
          countdown--;
          dialogRef.componentInstance.htmlContent = `<p>Your SMS verification service has expired. To continue please 
          enable the <strong>Authenticator App</strong>. You will be redirected in ${countdown} seconds...</p>`;

          if (countdown <= 0) {
            clearInterval(interval);
          }
        }, 1000);

        // Automatically close the dialog after 10 seconds and redirect
        setTimeout(() => {
          clearInterval(interval);
          dialogRef.close();
        }, 9000);


        setTimeout(() => {
          document.location.href = environment.onboardingClaims.twoFactorAuthentication;
          resolve(false);
        }, 9000);

      });
    } else {
      return true;
    }
  } else {
    return true;
  }
}