import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { CREATE_USER_CLAIM, CreateUserClaimData, CreateUserClaimInput, CreateUserClaimResponse } from '../graphql/mutations/create-claim-by-user-sms.graphql';
import { DELETE_USER_CLAIM, DeleteUserClaimData, DeleteUserClaimResponse } from '../graphql/mutations/delete-claim-by-user-id-sms.graphql';
import { UPDATE_USER_CLAIM, UpdateUserClaimInputModel, UpdateUserClaimResponse } from '../graphql/mutations/update-claim-by-user-sms.graphql';
import { GET_USER_CLAIM_BY_TYPE, GetUserClaimResponse, UserClaim, UserSmsClaimValue } from '../graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsSetupService {

  private readonly _graphqlHostService = inject(GraphqlHostService);
  private readonly httpClient = inject(HttpClient);

  readonly _loading = signal<boolean>(false);
  isSmsServiceActive = new BehaviorSubject<boolean>(false);
  userClaimValue = new BehaviorSubject<UserSmsClaimValue | null>(null);

  payloadUserClaim = signal<UserSmsClaimValue | null>(null);

  constructor() { }


  /**
   * Creates a new user claim using a GraphQL mutation.
   * @param createUserClaimInput - The input data required to create a user claim.
   * @returns A promise that resolves with the created user claim data.
   */
  createUserClaim(createUserClaimInput: CreateUserClaimInput): Promise<CreateUserClaimData> {
    // Set loading state to true before making the request
    this._loading.set(true);

    const vars = {
      input: createUserClaimInput
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs, // Target GraphQL server
          CREATE_USER_CLAIM, // GraphQL mutation for creating a claim
          vars, // Variables passed to the mutation
        )
        .then((response: CreateUserClaimResponse) => {
          this._loading.set(false); // Reset loading state after successful response
          resolve(response.createUserClaim.data); // Resolve with the claim data
        })
        .catch((error: string) => {
          this._loading.set(false); // Reset loading state in case of an error

          // Construct an error object with metadata
          const gError = {
            origin: 'createUserClaim',
            error,
          };

          reject(gError); // Reject the promise with the error object
        });
    });
  }

  /**
  * Deletes an existing user claim using a GraphQL mutation.
  * @param claimId - The ID of the claim to be deleted.
  * @returns A promise that resolves with the deletion status.
  */
  deleteUserClaim(claimId: string): Promise<DeleteUserClaimData> {
    // Set loading state to true before making the request
    this._loading.set(true);

    const vars = {
      id: claimId
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs, // Target GraphQL server
          DELETE_USER_CLAIM, // GraphQL mutation for deleting a claim
          vars, // Variables passed to the mutation
        )
        .then((response: DeleteUserClaimResponse) => {
          this._loading.set(false); // Reset loading state after successful response
          resolve(response.deleteUserClaims.data); // Resolve with deletion confirmation
        })
        .catch((error: string) => {
          this._loading.set(false); // Reset loading state in case of an error

          // Construct an error object with metadata
          const gError = {
            origin: 'deleteUserClaim',
            error,
          };

          reject(gError); // Reject the promise with the error object
        });
    });
  }

  /**
   * Updates an existing user claim using a GraphQL mutation.
   * 
   * @param userInputClaimModel - The input model containing the updated claim data.
   * @returns A promise that resolves with the updated claim response or rejects with an error.
   *          The response includes the updated claim data if successful, or error information if the update fails.
   */
  updateUserClaim(userInputClaimModel: UpdateUserClaimInputModel): Promise<UpdateUserClaimResponse> {
    // Set loading state to true before making the request
    this._loading.set(true);

    const vars = {
      input: userInputClaimModel
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.rbs, // Target GraphQL server
          UPDATE_USER_CLAIM, // GraphQL mutation for updating a claim
          vars, // Variables passed to the mutation
        )
        .then((response: UpdateUserClaimResponse) => {
          this._loading.set(false); // Reset loading state after successful response

          if (response.updateUserClaim.errors?.length) {
            reject({
              origin: 'updateUserClaim',
              error: response.updateUserClaim.errors.map(err => err.message).join(', '),
            });
          } else {
            resolve(response); // Resolve with updated claim data
          }
        })
        .catch((error: string) => {
          this._loading.set(false); // Reset loading state in case of an error

          // Construct an error object with metadata
          const gError = {
            origin: 'updateUserClaim',
            error,
          };

          reject(gError); // Reject the promise with the error object
        });
    });
  }

  /**
   * Checks if a user has a specific claim type.
   * This function queries the GraphQL server to determine if a user has any claims of the specified type.
   *
   * @param userId - The unique identifier of the user to check for claims.
   * @param claimType - The type of claim to search for.
   * @returns A Promise that resolves to a boolean:
   *          - true if the user has at least one claim of the specified type
   *          - false if the user has no claims of the specified type
   *          The Promise is rejected if there's an error during the query process.
   */
  getUserHasClaim(userId: string, claimType: string): Promise<boolean> {
    this._loading.set(true); // Set loading state to true before making the request

    const policy: WatchQueryFetchPolicy = 'network-only';
    const vars = { userId, claimType };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs, // Target GraphQL server
          GET_USER_CLAIM_BY_TYPE, // Dynamic GraphQL query
          vars, // Variables passed to the query
          null,
          policy
        )
        .then((response) => {
          this._loading.set(false); // Reset loading state after receiving response
          resolve(response.userClaims.nodes.length > 0); // Return true if at least one claim exists
        })
        .catch((error: string) => {
          this._loading.set(false); // Reset loading state in case of an error
          reject({
            origin: 'getUserHasClaim',
            error,
          });
        });
    });
  }

  /**
   * Retrieves a specific user claim based on the user ID and claim type.
   * This function queries the GraphQL server to fetch the first matching claim for the given parameters.
   *
   * @param userId - The unique identifier of the user whose claim is being retrieved.
   * @param claimType - The type of claim to search for.
   * @returns A Promise that resolves to a UserClaim object representing the first matching claim.
   *          If no matching claim is found, it will resolve to undefined.
   *          The Promise is rejected if there's an error during the query process, with an object containing the error details.
   */
  getUserClaimByName(userId: string, claimType: string): Promise<UserClaim> {

    const policy: WatchQueryFetchPolicy = 'network-only';
    const vars = { userId, claimType };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getQueryResults(
          environment.graphqlServerName.rbs, // Target GraphQL server
          GET_USER_CLAIM_BY_TYPE, // Dynamic GraphQL query
          vars, // Variables passed to the query
          null,
          policy
        )
        .then((response:GetUserClaimResponse) => {
          resolve(response.userClaims.nodes[0]); // Return true if at least one claim exists
        })
        .catch((error: string) => {
          reject({
            origin: 'getUserClaimByName',
            error,
          });
        });
    });
  }

}