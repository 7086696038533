import { gql } from 'apollo-angular';

export const CREATE_USER_CLAIM = gql`
mutation createUserClaim($input: UserClaimsInputModelInput!) {
    createUserClaim(
      input: $input
    ) {
      data {
        id
        userId
        claimValue
        originalType
      }
    }
  }
`;

// Input type for creating a user claim
export interface CreateUserClaimInput {
  claimType: string;  // Type of claim (e.g., "SmsServiceExpirationDate")
  claimValue: string; // Claim value (e.g., calculated expiration date)
  userId: string;     // User ID associated with the claim
}

// Response structure for the mutation
export interface CreateUserClaimResponse {
  createUserClaim: {
    data: CreateUserClaimData;
  };
}

// Data returned from the mutation
export interface CreateUserClaimData {
  id: string;          // Unique ID of the claim
  userId: string;      // ID of the user associated with the claim
  claimValue: string;  // Value stored in the claim (e.g., expiration date)
  originalType: string; // The original type of the claim
}
