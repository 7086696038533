<div class="step-container">
    <div class="form-container">
        <div class="description-side">
            <p class="title">User Information</p>
            <p class="subtitle">Please verify the user's identity before proceeding with the SMS service setup.</p>
            @if (isSmsServiceActive && !isCheckingStatus) {
                <app-status-banner [icon]="'info'" [description]="smsServiceBanner"></app-status-banner>
            }
        </div>
        <div class="form-side">
            <div class="flex w-full">
                <div class="w-1/2 info-item">
                    <p class="subtitles">First Name</p>
                    <p>{{user.firstName ? user.firstName : '--'}}</p>
                </div>
                <div class="w-1/2 info-item">
                    <p class="subtitles">Last Name</p>
                    <p>{{user.lastName ? user.lastName : '--'}}</p>
                </div>
            </div>
            <div class="info-item">
                <p class="subtitles">Username</p>
                <p>{{user.userName}}</p>
            </div>
            <div class="info-item">
                <p class="subtitles">Contact Email</p>
                <p>{{user.email}}</p>
            </div>
            <!-- Disable check after completing request "verified user" -->
            <mat-checkbox [disabled]="userIdVerificationComplete" class="mt-1 ml-2" [formControl]="idVerificationComplete" color="primary" required><strong>ID verification complete</strong></mat-checkbox>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="btns-container">
        <button mat-button mat-dialog-close color="primary">Cancel</button>
        <button mat-flat-button 
                color="primary" 
                type="button"
                [disabled]="idVerificationComplete.invalid || _loadingSignal()" 
                [class.user-portal-spinner]="_loadingSignal()"
                (click)="setIdVerificationCompletedByUser()">Next</button>
    </div>
</div>