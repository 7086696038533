<div class="summary-content">
    <div class="summary-header">
        <p><strong>Success!</strong> The SMS service is now enabled for this user:</p>
    </div>
    <div class="user-info-container">
        <div class="user-info-item">
            <p><strong class="sub-title-form">Username:</strong></p>
            <p>{{user.userName}}</p>
            <p><strong class="sub-title-form">Created Date:</strong></p>
            <p>{{todaysDate | date: 'longDate'}}</p>
        </div>
        <div class="user-info-item">
            <p><strong class="sub-title-form">User’s Phone Number:</strong></p>
            <p>{{(isLoading | async) ? 'Loading...' : '+' + userClaimValue.phoneNumber}}</p>
            <p>
                <strong class="sub-title-form">It Will Expire In 60 Days On:</strong>
            </p>
            <p>{{(isLoading | async) ? 'Loading...' : userClaimValue.deActivationDate | date:'longDate'}}</p>
        </div>
    </div>
    <div class="py-1 pb-5 alert alert-warning">
        <app-status-banner icon="info" title="Important" description="Ensure the user completes a successful login using SMS before concluding the session.
        This ensures that the user has verified and tested their access to the SMS service."></app-status-banner>
    </div>
    <p class="label-days">After 60 Days – Please Let The User Know</p>
    <ul class="list-none agreements-list">
        <li><p>✔ The User <strong>WILL</strong> be able to log in, but will be forced to set up the Authentication App.</p></li>
        <li><p>✔ They <strong>WILL NOT</strong> have access to anything in the LCP platform until the Authentication App is set up.</p></li>
        <li><p>✔ They <strong>CAN</strong> contact support for assistance in setting up the Authentication App.</p></li>
    </ul>
</div>
<div class="btns-container">
    <button type="button" mat-flat-button (click)="closeDialog()" color="primary">Close</button>
</div>