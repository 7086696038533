<div class="banner-container" 
     [ngClass]="{
        'bg-amber-100 text-amber-900 ring-amber-600/10': icon === 'info',
        'bg-green-100 text-green-800 ring-green-600/20': icon === 'success',
        'bg-red-100 text-red-900 ring-red-600/10': icon === 'error'
     }">
    <mat-icon>
        @switch (icon) {
            @case ('info') {
                info
            }
            @case ('success') {
                check_circle
            }
            @case ('error') {
                error
            }
            @default {
                info
            }
        }
    </mat-icon>
    <div>
        @if (title) {
            <p class="title">{{title}}</p>
        }
        <!-- <div [innerHTML]="description"></div> -->
        <p>{{description}}</p>
    </div>
</div>