import { gql } from 'apollo-angular';

export const UPDATE_USER_CLAIM = gql`
  mutation updateUserClaim($input: UserClaimsInputModelInput!) {
    updateUserClaim(input: $input) {
      data {
        id
        userId
        claimType
        claimValue
        issuer
        originalType
        __typename
      }
      errors {
        message
        extensions {
          errorCode
          propertyName
          attemptedValue
          severity
          __typename
        }
      }
    }
  }
`;

export interface UpdateUserClaimResponse {
  updateUserClaim: {
    data: UpdateUserClaimData | null;
    errors?: UpdateUserClaimError[];
  };
}

export interface UpdateUserClaimData {
  id: string;
  userId: string;
  claimType: string;
  claimValue: string;
  issuer?: string;
  originalType?: string;
  __typename: string;
}

export interface UpdateUserClaimError {
  message: string;
  extensions: {
    errorCode: string;
    propertyName: string;
    attemptedValue: unknown;
    severity: string;
    __typename: string;
  };
}

export interface UpdateUserClaimInputModel {
  userId: string | null; 
  claimType: string | null; 
  claimValue: string | null;
}