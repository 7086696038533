import { gql } from 'apollo-angular';

export const GET_USER_CLAIM_BY_TYPE = gql`
  query getUserClaims($userId: UUID!, $claimType: String!) {
    userClaims(where: {
      userId: { eq: $userId },
      claimType: { eq: $claimType }
    }) {
      nodes {
        id
        userId
        claimType
        claimValue
        issuer
        originalType
        user {
          id
          userName
        }
      }
    }
  }
`;

export interface UserSmsClaimValue {
  verifiedBy: string;
  phoneNumber: string;
  acknowledgementsAgreed: boolean;
  deActivationDate: string;
  enabled: boolean;
}

export interface UserClaim {
  id: string;
  userId: string;
  claimType: string;
  claimValue: string;
  issuer: string;
  originalType: string;
  user: {
    id: string;
    userName: string;
  };
}

export interface GetUserClaimResponse {
  userClaims: {
    nodes: UserClaim[];
  };
}