import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserPortalDialogService } from '../dialog/user-portal-dialog.service';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class IdentityProviderInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url === environment.authentication.silentRenewUrl) {
            // Intercept the request and proceed with it.
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse && error.status === 400) {
                        const refreshTokenExpired = new Date();
                        localStorage.setItem('refreshTokensessionEndedAt', refreshTokenExpired.toString());
                        this.authenticationService.logout();
                    }

                    // Return a new HttpErrorResponse with the modified error message.
                    return throwError(() => {
                        const httpResponse = {
                            error: 'Session expired',
                            status: 400,
                            statusText: 'Bad Request',
                        }
                        return httpResponse;
                    });
                })
            );
        } else {
            //No modification for other requests.
            return next.handle(request);
        }
    }
}
