import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot,
} from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { DashboardContentfulPage } from '../graphql/dashboard-data.query';
import { CsoPaymentService } from '../../payment-cso/services/cso-payment.service';
import { ContenfultStatusService } from 'src/app/core/services/contenfult-status.service';


export const dashboardContentResolver: ResolveFn<DashboardContentfulPage | null> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const contentfulStatusService = inject(ContenfultStatusService);
  const csoPaymentService = inject(CsoPaymentService);
  const dashboardService = inject(DashboardService);

  try {
    const isCsoEnabled = csoPaymentService.csoStatusEnabled();
    const hasValidLicense = csoPaymentService.hasValidLicense();
    const isContractor = csoPaymentService.isContractor();

    if (!isCsoEnabled) {
      return await dashboardService.fetchDashboardPage('cache-first', false);
    }

    if (isContractor && !hasValidLicense) {
      try {
        const nonPromotionalContent = await dashboardService.fetchDashboardPage('cache-first', false);
        const promotionalContent = await dashboardService.fetchDashboardPage('cache-first', true);

        return {
          ...nonPromotionalContent,
          lcpCarouselCollection: {
            items: [
              ...(promotionalContent?.lcpCarouselCollection?.items ?? []),
              ...(nonPromotionalContent?.lcpCarouselCollection?.items ?? []),
            ],
          },
        };
      } catch (innerError) {
        console.error('⚠️ Error fetching promotional content, falling back to default:', innerError);
        return await dashboardService.fetchDashboardPage('cache-first', false);
      }
    }

    return await dashboardService.fetchDashboardPage('cache-first', false);

  } catch (error) {
    console.error('❌ Error in dashboardContentResolver:', error);
    contentfulStatusService.setErrorStatus(true);

    // 🛑 Fake Return to avoid resolver sticks the application
    return {
      lcpCarouselCollection: {
        items: [],
      },
    } as unknown as DashboardContentfulPage;
  }
};

export const appTilesContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchAppTiles('cache-first');
}
