<div class="user-information">
  <form [formGroup]="userForm" (submit)="saveUserForm(user)">
    <div class="user-col-header">
      <h5>User</h5>
      <div class="users-actions">
        <button type="button" class="edit-btn" mat-stroked-button (click)="enableUserForm(user)" *ngIf="!editUserInfo">Edit</button>
        <button type="sumbit" class="save-btn" color="primary" mat-flat-button *ngIf="editUserInfo" [disabled]="userForm.invalid || isSaving()">
          {{isSaving() ? 'Saving' : 'Save'}}</button>
        <button [ngClass]="{'disable-button-state': isSaving()}" class="cancel-btn" type="button"
          mat-stroked-button *ngIf="editUserInfo" (click)="enableUserForm(user)"
          [disabled]="isSaving()">Cancel</button>
      </div>
    </div>

    <div class="pl-0.57">
      <p class="label">Username</p>
      <p class="value">{{user.node.userName ? user.node.userName : '-'}}</p>

      <div *ngIf="!editUserInfo">
        <p class="label">First name</p>
        <p class="value">{{user.node.firstName ? user.node.firstName : '--'}}</p>

        <p class="label">Last name</p>
        <p class="value">{{user.node.lastName ? user.node.lastName : '--'}}</p>

        <p class="label">Email</p>
        <p class="value">{{user.node.email ? user.node.email : '-'}}</p>
      </div>

      <div *ngIf="editUserInfo">
        <!-- First Name Form Field -->
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" name="{{user.node.firstName}}">
        </mat-form-field>
        <!-- Last Name Form Field -->
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" name="{{user.node.lastName}}">
        </mat-form-field>

        <!-- Email Form Field -->
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Email</mat-label>
          <input matInput type="text" formControlName="userEmailAddress" name="{{user.node.email}}"
            (copy)="preventCopyingOnClipboardEvent($event)" (paste)="preventPasteOnClipboardEvent($event)">
        </mat-form-field>

        <!-- email confirmation field -->
        <mat-form-field appearance="fill" class="w-full"
          [ngClass]="userForm.controls['emailConfirmation'].hasError('checkEmailIsMatched') ? 'mb-8' : ''"
          *ngIf="editUserInfo">
          <mat-label>Confirm Email</mat-label>
          <input matInput type="text" formControlName="emailConfirmation"
            (copy)="preventCopyingOnClipboardEvent($event)" (paste)="preventPasteOnClipboardEvent($event)">

          <!-- Error Hint -->
          <mat-hint>
            <ng-container *ngFor="let validation of formValidationMessages['confirmEmail']">
              <p class="error-message" *ngIf="userForm.controls['emailConfirmation'].hasError(validation.type) &&
                (userForm.controls['emailConfirmation'].dirty || userForm.controls['emailConfirmation'].touched)
                ">{{validation.message}}</p>
            </ng-container>
          </mat-hint>
        </mat-form-field>
      </div>


      <p class="label">Two Factor Method</p>
      <p class="value " [ngClass]="(user.node.twoFactorEnabled) ? 'enabled' : 'disabled'">
        <mat-icon>key</mat-icon>
        {{user.node.twoFactorEnabled ? twoFAmethodSubject.value : 'Two Factor is not enabled'}}
      </p>

      <p class="label">Phone Extension</p>
      <p class="value">{{user.node.phoneExtension ? user.node.phoneExtension : '-'}}</p>

      <div *ngIf="!editUserInfo">
        <p class="label">Phone Number</p>
        <p class="value">{{user.node.phoneNumber ? (user.node.phoneNumber | phoneNumber) : '000-000-0000'}}</p>
      </div>

      <div *ngIf="editUserInfo">
        <!-- Phone Number Form Field -->
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Phone Number</mat-label>
          <input only-numbers matInput appPhoneNumberFormat type="text" formControlName="phoneNumber" name="{{user.node.phoneNumber}}" maxlength="14">

          <!-- Error Hint -->
          <mat-hint>
            <ng-container *ngFor="let validation of formValidationMessages['phoneNumberFormat']">
              <p class="error-message" *ngIf="userForm.controls['phoneNumber'].hasError(validation.type) &&
                (userForm.controls['phoneNumber'].dirty || userForm.controls['phoneNumber'].touched)
                ">{{validation.message}}</p>
            </ng-container>
          </mat-hint>
        </mat-form-field>
      </div>

      <p class="label">Suffix</p>
      <p class="value">{{(user.node.suffix) ? user.node.suffix : '-'}}</p>

      <p class="label">Prefix</p>
      <p class="value">{{(user.node.prefix) ? user.node.prefix : '-'}}</p>

      <button class="change-log-btn" mat-button type="button"
        (click)="openChangeLogDialog(user.node.id ,user.node.firstName, user.node.lastName, user.node.email, user.node.userName)">Console
        Change Log</button>
    </div>
  </form>
</div>