import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContenfultStatusService {

  // Global signal for Contentful API errors
  contentfulError = signal<boolean>(false);

  // Method to update the signal
  setErrorStatus(hasError: boolean) {
    this.contentfulError.set(hasError);
  }

}
