import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { environment } from 'src/environments/environment';
import { SEND_SMS_CODE, SendSmsCodeData, SendSmsCodeResponse, VERIFY_SMS_CODE, VerifySmsCodeData, VerifySmsCodeResponse } from '../graphql/mutations/sms-send-verify-code.query.graphql';

@Injectable({
  providedIn: 'root'
})
export class SmsTwilioService {
  private readonly _graphqlHostService = inject(GraphqlHostService);
  private readonly httpClient = inject(HttpClient);

  readonly _loading = signal<boolean>(false);
  readonly _isSendingCode = signal<boolean>(false);

  constructor() { }

  /**
   * Sends a verification SMS code to the specified phone number.
   * 
   * This function initiates the process of sending an SMS verification code to the provided
   * phone number for its further validation
   * 
   * @param phoneNumber - The phone number to which the verification code will be sent.
   * @returns A Promise that resolves with the response from the SMS sending operation or rejects with an error object.
   */
  sendVerificationSmsCode(phoneNumber: string): Promise<SendSmsCodeData> {
    this._isSendingCode.set(true);
    const vars = {
      phoneNumber
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.email,
          SEND_SMS_CODE, 
          vars,
        )
        .then((response: SendSmsCodeResponse) => {
          resolve(response.sendSMSCode); 
          this._isSendingCode.set(false);
        })
        .catch((error: string) => {
          this._isSendingCode.set(false);
          const gError = {
            origin: 'sendVerificationSmsCode',
            error,
          };
          reject(gError);
        });
    });
  }

  /**
   * Verifies the SMS code sent to a specified phone number.
   * 
   * @param phoneNumber - The phone number to which the verification code was sent.
   * @param code - The verification code to be checked.
   * @returns A Promise that resolves with the verification response or rejects with an error object.
   */
  verifyVerificationSmsCode(phoneNumber: string, code: string): Promise<VerifySmsCodeData> {
    this._loading.set(true);
    const vars = {
      phoneNumber,
      code
    };

    return new Promise((resolve, reject) => {
      this._graphqlHostService
        .getMutationResults(
          environment.graphqlServerName.email,
          VERIFY_SMS_CODE,
          vars, 
        )
        .then((response: VerifySmsCodeResponse) => {
          resolve(response.verifySMSCode); 
          this._loading.set(false);
        })
        .catch((error: string) => {
          this._loading.set(false);
          const gError = {
            origin: 'sendVerificationSmsCode',
            error,
          };
          reject(gError);
        });
    });
  }
}
