import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserView } from 'src/app/features/support-console/graphql/support-console.queries.graphql';
import { SmsSetupService } from 'src/app/features/support-console/services/sms-setup.service';
import { SmsSetupComponent } from '../../sms-setup.component';
import { USER_CLAIM_SMS_SERVICE } from 'src/app/core/consts/core-userportal-const';
import { UserClaim, UserSmsClaimValue } from 'src/app/features/support-console/graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sms-setup-summary',
  templateUrl: './sms-setup-summary.component.html',
  styleUrl: './sms-setup-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsSetupSummaryComponent implements OnInit {
  @Input() user!: UserView
  private readonly _smsSetupService = inject(SmsSetupService);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _dialogRef = inject(MatDialogRef<SmsSetupComponent>);

  userClaim!: UserClaim;
  userClaimValue!: UserSmsClaimValue;

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  todaysDate: Date = new Date();

  ngOnInit(): void {
    this.getSmsStatus();
  }


  async getSmsStatus(): Promise<void> {
    this.isLoading.next(true);
    const userId = this.user.id;
    const userClaimName = USER_CLAIM_SMS_SERVICE;
    try {
      this.userClaim = await this._smsSetupService.getUserClaimByName(userId, userClaimName);

      if (!this.userClaim) {
        console.error('No SMS service claim found');
        return
      }

      if (!this.userClaim.claimValue) {
        console.error('No SMS service claim found');
      }
      this.userClaimValue = JSON.parse(this.userClaim.claimValue) as UserSmsClaimValue;

    } catch (error) {
      console.error('Error in method getSmsStatus: ', error);
    }
    finally {
      this.isLoading.next(false);
    }

  }

  closeDialog(): void {
    this._dialogRef.close(true);
  }
}
