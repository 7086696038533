<div class="login-help-container">
    <div mat-dialog-title class="header">
        <img [src]=logo alt="LCPtracker Logo" height="auto" width="150">
    </div>
    <mat-dialog-content>
        <div class="body">
            <p>
                To improve security, LCPtracker is implementing an urgent security enhancement. In addition to using
                Username and Password credentials, all users will be required to utilize two-factor authentication
                (2FA).
                This is an extra layer of protection that secures your data when accessing LCPtracker systems. This
                enhancement is part of our ongoing commitment to safeguarding your data and providing you with a
                more secure experience.
                In addition to security, you will also benefit from a smoother user experience with a more streamlined
                and convenient login process.
            </p>
            <p><strong>What login changes should I expect with this new update?</strong></p>
            <ul>
                <li>
                    Once Fortify is enabled, you will be required to use an email address to log in instead of a
                    10-digit
                    phone number or contractor license number. Along with this change, you should now also be able
                    to access all products and accounts through a single login instead of multiple login credentials.
                </li>
                <li>
                    Accessing your account will require 2FA, meaning you'll need to provide more than just a password
                    during
                    the login process. Options
                    include using an e-mail passcode or an authenticator app, as detailed in the guide attached to this
                    e-mail.
                </li>
                <li>
                    Lastly, your user experience will be further enhanced through the introduction of new profile and
                    dashboard interfaces that are accessible
                    once you have logged into your account.
                </li>
            </ul>
            <p><strong>What do I need to do?</strong></p>
            <p>In addition, we kindly request the following steps to be taken as soon as possible to make sure your
                account
                is ready for onboarding:</p>
            <ul>
                <li>Ensure the main contact to each database is correct and up-to-date.</li>
                <li>Remove access to users that no longer are part of your organization</li>
                <li> Whitelist our email domain and allow emails from: <strong>donotreply&#64;lcptracker.com</strong> or 
                    <strong>noreply&#64;lcptracker.com</strong></li>
            </ul>
            <p><strong>Do I need to do anything within my LCPtracker mobile applications?</strong></p>
            <p>If you are a user of our mobile apps, you will need to make sure your device downloads the updates from
                your
                device's app store. Below are the
                mobile apps that need to be updated.
            </p>
            <ul>
                <li>Daily Reporter.</li>
                <li>OnSite.</li>
            </ul>

            <p><strong>What do I do after I receive my onboarding email?</strong></p>
            <ul>
                <li>It is crucial to upgrade your credentials in a timely manner in order to maintain access to your
                    LCPtracker account and minimize disruptions
                    to your payroll and compliance process.
                </li>
                <li>
                    Please complete onboarding within 7 days. You must reset your password if this process is not
                    completed
                    within this timeframe
                </li>
                <li>
                    Upon login, you will be prompted to update your credentials to an e-mail address. You will no longer
                    be
                    able to log in using your 10-digit
                    phone number or contractor license number.
                </li>
            </ul>

            <p><strong>Need additional information?</strong></p>
            <ul>
                <li>View our training guides <a target="_blank"
                        href="https://lcptracker.com/support-training-main/fortify-help-materials">here.</a></li>
                <li>
                    View our onboarding walkthrough video here: <a target="_blank"
                        href="https://lcptracker.com/wp-content/uploads/2023/08/Onboarding_updated-8.9.23.mp4">here.</a>
                </li>
            </ul>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mt-2" align="end">
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</div>