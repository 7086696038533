export const environment = {
  env: "dev",
  hideConsole: false,
  production: true,
  applications: {
    certified: {
      hostingUrl: "https://dev-certified.lcptracker.net/",
      login: "https://dev-certified-cdn.lcptracker.net"
    },
    lcptracker: {
      hostingUrl: "https://dev.lcptracker.net/",
      login: "https://dev-cdn.lcptracker.net",
      fullContractor: "https://dev-cdn.lcptracker.net/login/v2/full-contractor",
    },
    fortify: {
      api: "https://devv2.fortify.lcptracker.net/api",
      v2: 'https://devv2.fortify.lcptracker.net',
    },
  },
  authentication: {
    issuer: "https://devv2.fortify.lcptracker.net",
    redirectUrl: `${window.location.origin}/login/success`,
    postLogoutRedirectUri: window.location.origin,
    clientId: "UserPortSpa",
    responseType: "code",
    scope: "openid profile offline_access email theidserveradminapi MessagingService",
    silentRenewUrl: 'https://devv2.fortify.lcptracker.net/connect/token',
    renewTimeBeforeTokenExpiresValue: 300
  },
  contentful: {
    contentType: {
      footer: "footer",
      howCanI: "howCanI",
      carousel: "carousel",
      marketingBanner: "marketingBanner",
      aboutUs: "aboutUs",
      infoCard: "infoCard",
    },
  },
  graphql: {
    rbs: "https://lcp-rbs-dev-appsvs.azurewebsites.net/graphql/",
    gateway: "https://lcp-rbs-dev-appsvs.azurewebsites.net/graphql/",
    geography: "https://lcp-geography-dev-appsvs.azurewebsites.net/graphql/",
    project: "https://lcp-project-dev-appsvs.azurewebsites.net/graphql/",
    wagedata: "https://lcp-wagedata-dev-appsvs.azurewebsites.net/graphql/",
    contentful: "https://graphql.contentful.com/content/v1/spaces/",
    email: 'https://dev-msgsvs.lcptracker.net/graphql/'
  },
  graphqlServerName: {
    gateway: "GatewayGraphQL",
    contentful: "ContentfulGraphQL",
    rbs: "RBSGraphQL",
    email: "EmailGraphQL",
  },
  onboardingClaims: {
    agreements: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Agreements",
    lastProfileChange: "https://devv2.fortify.lcptracker.net/identity/account/manage?culture=en",
    password: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/ChangePassword",
    twoFactorAuthentication: "https://devv2.fortify.lcptracker.net/Identity/Account/Manage/TwoFactorAuthentication",
    phoneNumber: 'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/PhoneNumber',
    email: 'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Email',
    providers: 'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/ExternalLogins',
    sessions: 'https://devv2.fortify.lcptracker.net/Identity/Account/Manage/Sessions'
  },
  LcpTrackerApi: 'https://dev.lcptracker.net/api/user',
  CertifiedApi: 'https://dev-certified.lcptracker.net/api/user',
  azureServices: "https://lcp-fortify-legacydatabridge-dev-appsvs.azurewebsites.net/Keys/GetKey?keyName=",
  userPortalHost: 'https://dev.userportal.lcptracker.net',
  supportConsoleHost: 'https://ashy-tree-00429751e.4.azurestaticapps.net/',
  lcptrackerPlusPlans: {
    monthly: 'https://csotest.chargifypay.com/subscribe/3bdzp574tb2j/monthly',
    annual: 'https://csotest.chargifypay.com/subscribe/kqksrd893vr4/annual'
  },
};
