<div class="step-container">
    <div class="form-container">
        <p class="title">Agree to SMS Verification Terms</p>
        <ol>
            <li class="agreement-term">
                <p>Authentication App use is highly recommended now, and will be required within 60 days.</p>
            </li>
            <li class="agreement-term">
                <p>Text messages will ONLY works for 60 days. User must upgrade to the more secure Authentication App by
                    that time. After 60 days, log in
                    will only take them to the Authentication App setup. They will have *<strong>NO OTHER</strong>*
                    access to the LCP Platform at that point. They
                    *<strong>CAN</strong>* contact LCP Support for help in setting up the Authentication App.</p>
            </li>
            <li class="agreement-term">
                <p>User cannot enable SMS from their own profile.</p>
            </li>
        </ol>

        <p class="title">User understands and agrees to the following:</p>
        <form [formGroup]="agreementsForm">
            <div class="checks-container">
                <mat-checkbox formControlName="receiveTextMessages" color="primary" required>User agrees to receive
                    system generated text messages.</mat-checkbox>
                <mat-checkbox formControlName="timeLimit" color="primary" required>Text message will ONLY work for the
                    next 60 days.</mat-checkbox>
                <mat-checkbox formControlName="useAuthApp" color="primary" required>The Authentication App must be used
                    after that.</mat-checkbox>
            </div>
        </form>
    </div>
    <mat-divider></mat-divider>
    <div class="btns-container">
        <button type="button" mat-button matStepperPrevious color="primary">Back</button>
        <button mat-flat-button type="button" (click)="setUpSmsService()" color="primary"
            [disabled]="agreementsForm.invalid || loadingData()"
            [class.user-portal-spinner]="loadingData()">Enable</button>
    </div>
</div>