import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { LinkList } from "../../interfaces/hyperlink-list.interface";
import { ProductList } from "../../interfaces/product-list.interface";
import { MatBottomSheet, MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { LocationService } from "src/app/shared/services/location/location.service";
import { LoginBottomSheetWrapperComponent } from "./../../components/login-bottom-sheet-wrapper.component";
import { LoginDialogWrapperComponent } from "../../components/login-dialog-wrapper.component";
import { CommonModule, DatePipe } from "@angular/common";
import { OidcSecurityService} from "angular-auth-oidc-client";
import { AuthenticationService } from "../../services/authentication.service";
import { HeaderLinks, FooterLinks, ProductLinks, } from "./landing-consts";
import { UserPortalDialogService } from "src/app/shared/services/dialog/user-portal-dialog.service";
import { LandingService } from "../../services/landing.service";
import { LandingImage } from "../../graphql/get-landing-banner.graphql.query";
import { from, Observable, switchMap } from "rxjs";
import { cleanLocalStorage } from "src/app/shared/helpers";
import { AzureService } from "src/app/core/services/azure.service";
import { FooterComponent } from "src/app/shared/components/footer/footer.component";
import { SharedModule } from "src/app/shared/shared.module";
import { LoginHelpComponent } from "../../components/login-help/login-help.component";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
  standalone:true,
  imports: [CommonModule, SharedModule]
})
export class LandingComponent implements OnInit {
  logo: string = "assets/images/lcptracker-full-color.svg";

  tagline: string = `
  Cloud-based certified payroll, labor compliance, and workforce management solutions that provide insight on the transformative impact of your projects on our communities, and more importantly, help workers create a path to success.
  `;

  headerLinkList: LinkList[] | null = HeaderLinks;
  footerLinkList: LinkList[] | null = FooterLinks;
  productList: ProductList[] | null = ProductLinks;
  landingBanner: LandingImage = { description: "", url: "", title: ""};
  isLoading = new Observable<boolean>();

  shouldOpenModal!: boolean;

  constructor(
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe,
    private userPortalDialogService: UserPortalDialogService,
    public oidcSecurityService: OidcSecurityService,
    public authenticationService: AuthenticationService,
    public locationService: LocationService,
    public landingService: LandingService,
    public azureService: AzureService,
  ) {
    this.breakpointObserver
      .observe('(min-width: 640px)')
      .subscribe((state: BreakpointState) => {
        this.shouldOpenModal = state.matches;
      });
  }

  currentYear: string = '2024';
  lcpCopyrightLine: string = `© COPYRIGHT ${this.currentYear} LCPTRACKER, INC.`;

  ngOnInit(): void {
    this.loadUserPortalCoreData();
    this.loadingState(); 
    this.datePipe.transform(new Date(), 'yyyy') || '2024';
    this.authenticationService.createAuthDataObjectInLocalStorage();
    this.displayTokenExpiredSnackbar();
    this.displayLogoutConfirmationSnackbar();
    //this.locationService.handleUserLocation();
    cleanLocalStorage(['temp_ref_data_callback', 'aux_callback'])
  }

  loadUserPortalCoreData(): void {
    this.landingService.isLoadingSubject.next(true);
    this.azureService.getAzureApiLandingInfo().pipe(
      switchMap(() => from(this.getLandingPageBanner()))
    ).subscribe(
      {
        next: () => {
          this.landingService.isLoadingSubject.next(false);
        }
      }
    );
  }

  /**
   * Initializes the loading var
   */
  loadingState(): void {
    this.isLoading = this.landingService.isLoading$.pipe();
  }

  /**
   * Displays a Snack bar with a message that user logout successfully and
   * removes from the local storage the flag that indicates that user
   * logged out
   */
  displayLogoutConfirmationSnackbar(): void {
    // Stores the value from the item in local storage called session_ended_at
    const userPortalAuthData = this.authenticationService.getAuthDataFromLocalStorage();

    const sessionEndedAt = userPortalAuthData.sessionEndedAt;

    // Checks if the item session_ended_at has a value or not
    if (sessionEndedAt) {

      // If the session_ended_at item has a value opens a Snack Bar with a message
      this.userPortalDialogService.openUserPortalSnackBar('You are now logged out.');

      // Removes the session_ended_at item from local storage
      userPortalAuthData.sessionEndedAt = null;

      // Store new object in local storage
      this.authenticationService.setNewAuthenticationDataObjectInLocalStorage(userPortalAuthData);

    }

  }

  displayTokenExpiredSnackbar():void {
    if(localStorage.getItem('refreshTokensessionEndedAt')){
      localStorage.removeItem('refreshTokensessionEndedAt')
      // If the session_ended_at item has a value opens a Snack Bar with a message.
       this.userPortalDialogService.openUserPortalSnackBar('Your session has expired.');
    }
  }

  openLoginDialogWrapper(): void {
    this.dialog.open(LoginDialogWrapperComponent, {
      autoFocus: false,
      width: "515px",
      height: "fit-content",
    });
  }

  openUsernameCredentialComponent(): void {
    if (this.shouldOpenModal) {
      this.openLoginDialogWrapper();
    } else {
      this.openLoginBottomSheetWrapperComponent();
    }
  }

  openLoginBottomSheetWrapperComponent() {
    this.bottomSheet.open(LoginBottomSheetWrapperComponent);
  }

  getLandingPageBanner() {
    return this.landingService.getLandingBanner()
      .then(resp => {
        if (resp.items.length > 0) {
          this.landingBanner = resp.items[0].image;
        } else {
          this.landingBanner = { description: '', url: '', title: ' ' };
        }
      });
  }

  openLoginHelpDialog(): void {
    this.dialog.open(LoginHelpComponent, {
      autoFocus: false,
      minWidth: '250px',
      maxWidth: '950px',
    });
  }
}
