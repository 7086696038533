import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-banner',
  templateUrl: './status-banner.component.html',
  styleUrl: './status-banner.component.scss'
})
export class StatusBannerComponent {
  @Input() icon: 'info' | 'success' | 'error' = 'info';
  @Input() description: string = '';
  @Input() title?: string = '';
}
