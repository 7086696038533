import { gql } from 'apollo-angular';

export const SEND_SMS_CODE = gql`
mutation sendSmsCode($phoneNumber: String!) {
    sendSMSCode(phoneNumber: $phoneNumber) {
        data
        errors {
            message
        }
    }
}
`;

export const VERIFY_SMS_CODE = gql`
mutation verifySmsCode($phoneNumber: String!, $code: String!) {
    verifySMSCode(phoneNumber: $phoneNumber, code: $code) {
        data
        errors {
            message
        }
    }
}
`;

export interface SendSmsCodeData {
    data: boolean;
    errors: {
        message: string;
    }
}
export interface VerifySmsCodeData {
    data: boolean;
    errors: {
        message: string;
    }
}
export interface SendSmsCodeResponse {
    sendSMSCode: SendSmsCodeData
}
export interface VerifySmsCodeResponse {
    verifySMSCode: VerifySmsCodeData
}
