import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UsersEdge } from '../../../graphql/support-console.queries.graphql';
import { FormValidationMessages } from '../../../interfaces/interfaces';
import { UsersService } from '../../../services/users.service';
import { ChangeLogComponent } from '../../modals/change-log/change-log.component';
import { TwoFactorMethod } from '../../../graphql/two-factor-method.queries.graphql';
import { UpdateUserData } from '../../../graphql/mutations/edit-user-data.query.graphql';
import { UsersComponent } from '../../users/users.component';
import { CreateChangeLog } from '../../../graphql/change-log.queties.graphql';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { MessagingEmailService } from 'src/app/shared/services/email-service/messaging-email.service';
import { EmailInputData } from 'src/app/shared/graphql/messaging-email.mutation';
import { AzureService } from 'src/app/core/services/azure.service';
import { SmsSetupComponent } from '../../modals/sms-setup/sms-setup.component';
import { SmsSetupService } from '../../../services/sms-setup.service';
import { UserClaim } from '../../../graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _formBuilder = inject(FormBuilder);
  private _portalDialogService = inject(UserPortalDialogService);
  public _dialog = inject(MatDialog);
  private _usersComponent = inject(UsersComponent);
  private _authService = inject(AuthenticationService);
  private _usersService = inject(UsersService);
  private _messagingEmailService = inject(MessagingEmailService);
  private _azureService = inject(AzureService);
  private _smsSetupService = inject(SmsSetupService);
  
  @Input() user!: UsersEdge;
  @Output() newEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() twoFactorIndicator: EventEmitter<string> = new EventEmitter<string>();

  userForm!: FormGroup;
  DEFAULT_PHONE_NUMBER = '000-000-0000';
  DEFAULT_NAME = '--';
  isSaving = this._usersService._isSaving;
  editUserInfo: boolean = false;
  twoFAmethodSubject = new BehaviorSubject<string>('');

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  formValidationMessages: FormValidationMessages = {
    confirmEmail: [
      { type: 'checkEmailIsMatched', message: 'Email confirmation does not match email address.' },
      { type: 'required', message: 'Email confirmation is required.' },
    ],
    phoneNumberFormat: [
      { type: 'required', message: 'Phone number is required.' },
      { type: 'minLength', message: 'Phone number must be at least 10 characters' },
    ]
  };

  ngOnInit(): void {
    this.UserFormInit();
    if (this.user.node.twoFactorEnabled) {
      this.validateTwoFAmethod();
    }
  }

  /**
   * Initializes the user form controls
   */
  UserFormInit(): void {
    this.userForm = this._formBuilder.group({
      userEmailAddress: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
      emailConfirmation: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
      firstName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(2)] }),
      lastName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(2)] }),
      phoneNumber: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.minLength(10)] }),
    }, {
      validators: this.validateFormFields('userEmailAddress', 'emailConfirmation'),
    });
  }

  /**
   * Enables the emailAddress input and buttons events.
   * @param user
   */
  enableUserForm(user: UsersEdge): void {
    this.editUserInfo = !this.editUserInfo;
    this.userForm.controls['userEmailAddress'].patchValue(user.node.email);
    this.userForm.patchValue(user.node);
    this.userForm.controls['emailConfirmation'].setValue(null);
  }

  /**
   * Compares the two email fields to evaluate their equality
   * if they're not, and ControlError is set to indicate that
   * @param emailCheck 
   * @param confirmEmailCheck 
   * @returns ValidationErrors
   */
  validateFormFields(emailCheck: string, confirmEmailCheck: string): ValidationErrors {
    return (_formGroup: FormGroup) => {
      const controlEmail = _formGroup.controls[emailCheck];
      const controlConfirmEmail = _formGroup.controls[confirmEmailCheck];

      if (controlEmail.value !== null) {
        if (controlConfirmEmail.errors && controlConfirmEmail.errors['required']) {
          this.user.node.email === controlEmail.value ? controlConfirmEmail.setErrors(null) : controlConfirmEmail.setErrors({ required: true });
        } else if ((this.user.node.email !== controlEmail.value) && (controlEmail.value !== controlConfirmEmail.value)) {
          controlConfirmEmail.setErrors({ checkEmailIsMatched: true })
        } else {
          controlConfirmEmail.setErrors(null)
        }
      }
    }
  }

  /**
   * Prevent the user from copy in clipboard events.
   * @param event
   */
  preventCopyingOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   * Prevent the user from paste in clipboard events.
   * @param event
   */
  preventPasteOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   * Evaluates the authentication method for the selected user
   *
   */
  async validateTwoFAmethod(): Promise<void> {
    try {
      const userTokenClaims: UserClaim = await this._smsSetupService.getUserClaimByName(
        this.user.node.id,
        'TwoFactorType'
      );
      if (!userTokenClaims) {
        this.twoFAmethodSubject.next("Two Factor is not enabled");
        this.twoFactorIndicator.emit(`Two Factor is not enabled`);
        return;
      }

      if(userTokenClaims.claimValue === 'EmailOneTimePassword'){
        this.twoFAmethodSubject.next("Email OTP");
        this.twoFactorIndicator.emit(`Email OTP Enabled`);
      }else{
        this.twoFAmethodSubject.next(userTokenClaims.claimValue);
        this.twoFactorIndicator.emit(`${userTokenClaims.claimValue} Enabled`);
      }
    } catch (error) {
      console.error("Error get2FAmethod",error);
      this.twoFAmethodSubject.next("Not available");
      this.twoFactorIndicator.emit(`Not available`);
    }
  }

  async saveUserForm(user: UsersEdge): Promise<void> {
    await this.updateUser(user);
    await this.logChanges(user);
  }


  private async logChanges(user: UsersEdge): Promise<void> {
    /*
         * Temporary check for development environment only.
         * This condition is in place until the email service is replicated in other environments.
         * Once the email service is available in staging/production, this check can be removed 
         * or adjusted to include those environments as well. /* 
         **/
    if (environment.env === 'dev' || environment.env === 'local') {
      await this.newSaveChangeLog(user);
    } else {
      await this.saveChangeLog(user);
    }
  }


  /**
   * Saves the new email value
   * @param user
   */
  /* istanbul ignore next */
  updateUser(user: UsersEdge): void {
    const userForm: UpdateUserData = {
      userName: user.node.userName,
      email: this.userForm.controls['userEmailAddress'].value,
      firstName: this.userForm.controls['firstName'].value ? this.userForm.controls['firstName'].value : this.DEFAULT_NAME,
      lastName: this.userForm.controls['lastName'].value ? this.userForm.controls['lastName'].value : this.DEFAULT_NAME,
      phoneNumber: this.userForm.controls['phoneNumber'].value ? this.userForm.controls['phoneNumber'].value.replace(/\D/g, '') : this.DEFAULT_PHONE_NUMBER
    }

    if (this.evaluateValidFormChanges(user, userForm)) {
      this._usersService.updateUserData(userForm)
        .then(async response => {
          this.editUserInfo = !this.editUserInfo;
          this._usersComponent.expandPanelAfterRequest = user.node.id;
          this._portalDialogService.openUserPortalSnackBar(`User information updated`);
          this._usersComponent.getUsers();
        })
        .catch(error => {
          this.editUserInfo = !this.editUserInfo;
          this._portalDialogService.openUserPortalSnackBar(`Error updating user information, please try again`);
          console.error("Error: ", error);
        });
    }
  }

  /**
 * Saves a new log when a user's email is changed
 */
  saveChangeLog(user: UsersEdge): void {
    const changeLog: CreateChangeLog = {
      fieldsChanged: this.validateUserDataChangesToLog(user),
      changedVia: 'Support Console',
      userId: user.node.id,
      changedById: this._authService.userId.value
    }
    this._usersService.saveChangeLog(changeLog);
  }

  /**
   * Logs user profile changes and sends a notification email.
   *
   * This method sequentially executes several operations to record changes in 
   * user data, including logging modified fields, validating email credentials, 
   * and sending a notification email. If any of these operations fail, an error 
   * is thrown, and an error message is displayed to the user.
   *
   * @param {UsersEdge} user - The user object containing updated profile details.
   *
   * @throws {Error} Throws an error if any operation (saving the change log, 
   * sending an email notification, or validating email credentials) fails.
   *
   * @returns {Promise<void>} A promise that resolves once all operations complete successfully.
   */
  /* istanbul ignore next */
  async newSaveChangeLog(user: UsersEdge): Promise<void> {
    try {
      // Extract relevant data for change logging
      const { fieldsChanged, buildMutationParams, emailChanged } = this.newValidateUserDataChangesToLog(user);

      const changeLog: CreateChangeLog = {
        fieldsChanged,
        changedVia: 'Support Console',
        userId: user.node.id,
        changedById: this._authService.userId.value,
      };

      // Step 1: Save the change log and validate the operation
      const logResult = await this._usersService.saveChangeLog(changeLog);
      this.handleOperationResult(logResult, 'Change log save failed', 'Failed to save change log');

      const emailCredentials = this._azureService.emailCredentialsValue;
      if (!emailCredentials?.emailTemplate) {
        this._portalDialogService.openUserPortalSnackBar(
          `An error occurred while sending the notification email. Please contact the development team.`
        );
        this._usersService._loading.set(false);
        return;
      }

      // Prepare email input data
      const sendEmailInput: EmailInputData = {
        receiverEmail: emailChanged || user.node.email,
        username: user.node.userName,
        year: new Date().getFullYear().toString(),
        supportConsoleEmailId: emailCredentials.emailTemplate,
      };

      // Step 3: Send notification email
      const emailResult = await this._messagingEmailService.sendNotificationEmail(sendEmailInput, buildMutationParams);
      this.handleOperationResult(emailResult, 'Email notification failed', 'Failed to send notification email');

    } catch (error) {
      // Handle any unexpected errors and notify the user
      console.error("Error in newSaveChangeLog:", error);
      this._usersService._loading.set(false);
      throw error; // Re-throw the error for further handling
    }
  }



  /**
   * Evaluates if any inputs have a different value from the current user information
   * @param userInfo previous user information
   * @param form new value for the user information
   * @returns a boolean indicating if the form has any changes or not
   */
  evaluateValidFormChanges(userInfo: UsersEdge, form: UpdateUserData): boolean {
    const user = userInfo.node;
    if ((user.email === form.email) && (user.firstName === form.firstName) && (user.lastName === form.lastName) && (user.phoneNumber === form.phoneNumber)) {
      this._portalDialogService.openUserPortalSnackBar(`User information does not have changes`);
      return false;
    }
    return true;
  }


  /**
   * Helper method to handle operation results
   * @param result PromiseSettledResult of the operation
   * @param errorName Name of the error for throw
   * @param errorMessage Message to log
   */
  /* istanbul ignore next */
  private handleOperationResult(
    result: any,
    errorName: string,
    errorMessage: string
  ): void {
    if (result.status === 'rejected') {
      console.error(`${errorMessage}:`, result.reason);
      throw new Error(`${errorName}: ${result.reason.message || result.reason}`);
    }
  }


  /**
   * Open the change log modal
   * @param firstName
   * @param lastName
   * @param email
   * @param loginHistory
   */
  openChangeLogDialog(
    id: string,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    userName: string,
  ): void {
    const _dialogRef = this._dialog.open(ChangeLogComponent, {
      width: '950px',
      data: {
        id,
        name: `${firstName ? firstName : ' - '} ${lastName ? lastName : ' - '}`,
        email,
        userName
      },
      disableClose: false
    });

    _dialogRef.afterClosed().subscribe((result) => {
      if (environment.env !== 'prod') {
        console.log(`_dialog result: ${result}`);
      }
    });
  }

  /**
   * Evaluates if the formFields' values changed to add them to "values changed" item.
   * @param user the object with the previous user data
   * @returns a text with the new user data
   */
   /* istanbul ignore next */  newValidateUserDataChangesToLog(user: UsersEdge): { fieldsChanged: string; buildMutationParams: string, emailChanged: string | null } {
    let fieldsChanged: string = '';
    let buildMutationParams: string = '';
    let emailChanged = null;

    if (user.node.email !== this.userForm.controls['userEmailAddress'].value) {
      fieldsChanged += `Email: '${this.userForm.controls['userEmailAddress'].value}'. `;
      buildMutationParams += ` { key: "Email", value: "Contact Email" }`;
      emailChanged = this.userForm.controls['userEmailAddress'].value;
    }
    if (user.node.firstName !== this.userForm.controls['firstName'].value) {
      fieldsChanged += `First name: '${this.userForm.controls['firstName'].value}'. `;
      buildMutationParams += `{ key: "FN", value: "First Name" }`
    }
    if (user.node.lastName !== this.userForm.controls['lastName'].value) {
      fieldsChanged += `Last name: '${this.userForm.controls['lastName'].value}'. `;
      buildMutationParams += `{ key: "LN", value: "Last Name" }`
    }
    if (user.node.phoneNumber !== this.userForm.controls['phoneNumber'].value) {
      fieldsChanged += `Phone number: '${this.userForm.controls['phoneNumber'].value}'.`;
      buildMutationParams += `{ key: "PN", value: "Phone Number" }`
    }

    return { fieldsChanged, buildMutationParams, emailChanged };
  }

  validateUserDataChangesToLog(user: UsersEdge): string {
    let fieldsChanged = '';

    if (user.node.email !== this.userForm.controls['userEmailAddress'].value) {
      fieldsChanged += `Email: '${this.userForm.controls['userEmailAddress'].value}'. `;
    }
    if (user.node.firstName !== this.userForm.controls['firstName'].value) {
      fieldsChanged += `First name: '${this.userForm.controls['firstName'].value}'. `;
    }
    if (user.node.lastName !== this.userForm.controls['lastName'].value) {
      fieldsChanged += `Last name: '${this.userForm.controls['lastName'].value}'. `;
    }
    if (user.node.phoneNumber !== this.userForm.controls['phoneNumber'].value) {
      fieldsChanged += `Phone number: '${this.userForm.controls['phoneNumber'].value}'.`;
    }
    return fieldsChanged;
  }

  openSmsSetupDialog(user: UsersEdge): void {
    const _dialogRef = this._dialog.open(SmsSetupComponent, {
      width: '950px',
      data: { user: user.node },
      disableClose: false
    });

    _dialogRef.afterClosed().subscribe((result) => {
      if (environment.env !== 'prod') {
        console.log(`_dialog result: ${result}`);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}
