<!-- Dashboard Component Container -->
<section class="home-dashboard-container">

  <!-- Dashboard Body Content and Profile Card Container -->
  <div class="home-dashboard-body">

    <!-- Dashboard Body Content Container -->
    <div class="grid-cols-1 body-content">

      <!-- Body Title -->
      <h2 class="pb-2 m-0 home-dashboard-title">Your Dashboard</h2>

      @if (!contenfultError()) {
      <!-- Slideshow -->
      <app-slide-show *ngIf="slidesData || !hasErrorOccurredForContentful" [slides]="slidesData"></app-slide-show>
      }
      <!-- App Tiles Container -->
      <div *ngIf="appTiles || !hasErrorOccurredForAppTiles"
        class="grid p-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 xxl:grid-cols-4 lcp-tracker-card">

        <!-- App Tiles -->
        <ng-container *ngFor="let product of appTiles.tilesNodes">

          <!-- App Tile Component -->
          <ng-container *ngIf="product.uri && product.name">
            <app-card [product]="product"></app-card>
          </ng-container>

        </ng-container>

      </div>

      <!-- Profile Card Component -->
      <ng-container *ngIf="(isDesktop | async)">

        <!-- Profile Card Component -->
        <!-- <app-profile-card *ngIf="!hasErrorOccurredForProfile" class="profile-card" [profile]="profileCard"></app-profile-card> -->

      </ng-container>



      <!-- Marketing Advertisement Container -->
      <!-- <div *ngIf="marketingBannerData" class="marketing-advertisement-container"> -->

      <!-- Marketing Advertisement Component -->
      <!-- <app-marketing-advertisement *ngIf="!hasErrorOccurredForContentful"
          [advertisement]="marketingBannerData "></app-marketing-advertisement> -->
      <!-- </div> -->
      <!-- How Can I Component -->
      <!-- <app-how-can-i *ngIf="howCanIData || !hasErrorOccurredForContentful" [linksHowCanI]="howCanIData"></app-how-can-i> -->

    </div>

  </div>

</section>