import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const numericValue = value.replace(/\D/g, '').substring(0, 10); // Accepts only digits

    if (numericValue.length > 3) {
      return `(${numericValue.substring(0, 3)}) ${numericValue.substring(3, 6)}-${numericValue.substring(6, 10)}`;
    } else if (numericValue.length > 0) {
      return `(${numericValue.substring(0, 3)}`;
    } else {
      /*istanbul ignore next */
      return '';
    }
  }
}
