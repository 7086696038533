import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, WritableSignal } from '@angular/core';
import { HomePageLinks, TilesNode } from '../../components/app-card/app-list.interface';
import { LayoutStateService } from 'src/app/shared/layouts/portal/services/layout-state.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HowCanIInterface } from '../../components/how-can-i/how-can-i';
import { UserProfileData } from '../../graphql/profile-info.query';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { CarouselItem, MarketingBannerItem } from '../../graphql/dashboard-data.query';
import { ERROR_CATEGORY_CODE } from 'src/app/shared/components/error-handle/error-handle-const';
import { ContenfultStatusService } from 'src/app/core/services/contenfult-status.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  private readonly layoutState = inject(LayoutStateService);
  private readonly _route = inject(ActivatedRoute);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly contenfultStatusService = inject(ContenfultStatusService);

  ERROR_CATEGORY_CODE = ERROR_CATEGORY_CODE;
  ERROR_CODE: string = '';
  hasErrorOccurredForContentful: boolean = false;
  hasErrorOccurredForProfile: boolean = false;
  hasErrorOccurredForAppTiles: boolean = false;
  appTiles!: HomePageLinks;
  howCanIData!: HowCanIInterface[];
  slidesData!: CarouselItem[];
  marketingBannerData!: MarketingBannerItem;
  isDesktop = new Observable();

  private readonly unsubscribe: Subject<void> = new Subject<void>();

  constructor(

  ) { }

  //Signal
  contenfultError: WritableSignal<boolean> = this.contenfultStatusService.contentfulError;

  ngOnInit(): void { 
    this.getRevolverData();
    this.isDesktop = this.layoutState.isDesktopLayout$.pipe(takeUntil(this.unsubscribe));
  }

  appTilesForStaff(appTiles: HomePageLinks): void {
    // Filters the apps in a single array of app tiles to be displayed
    const filteredAppTiles = appTiles.tilesNodes.filter(
      (appTile: TilesNode): TilesNode | void => {
        // If the app tile does not have a restriction that only staff
        // can see it, the app tile gets in the list of displayed tiles
        if (!appTile.staffOnly) {
          return appTile
        }

        // If the app tile can only be displayed to staff users and the
        // the user has admin permissions, the app tile gets in the list
        // of displayed tiles
        if (this.authenticationService.canUseSupportToolsSubject.value) {
          return appTile
        }
      }
    )

    // Filtered app tiles get in the displayed array
    this.appTiles = {
      tilesNodes: filteredAppTiles
    };
  }

  // Retrieves data for the dashboard, processes it, and handles various error scenarios
  getRevolverData(): void {
    // Subscribe to the data observable from the route
    this._route.data.subscribe({
      next: data => {
        // Check if Contentful data was retrieved; if not, mock the data with the error handle data mock
        if (data['dashboard'].lcpCarouselCollection && data['dashboard'].howCanICollection && data['dashboard'].marketingBannerCollection) {
          this.slidesData = data['dashboard'].lcpCarouselCollection.items;
          this.howCanIData = data['dashboard'].howCanICollection.items;
          this.marketingBannerData = data['dashboard'].marketingBannerCollection.items[0];
          this.ERROR_CODE = '';
        } else {
          // Handle Contentful API error
          this.ERROR_CODE = ERROR_CATEGORY_CODE.Content_Retrieval_Error;
          this.hasErrorOccurredForContentful = true;
          this.slidesData = data['dashboard'].data;
          this.howCanIData = data['dashboard'].data;
          this.marketingBannerData = data['dashboard'].data;
        }
        // Validate and handle RBS resolver errors
        this.validateRbsResolver(data['appTiles']);
      },
    });
  }

  // Validates RBS resolver data and sets error flags accordingly
  validateRbsResolver(appTiles: HomePageLinks): void {
    // Check for appTiles error
    if (!appTiles.tilesNodes || !appTiles.tilesNodes.length) {
      this.ERROR_CODE = ERROR_CATEGORY_CODE.Internal_Service_Error;
      this.hasErrorOccurredForAppTiles = true;
    } else {
      this.appTilesForStaff(appTiles);
    }
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.unsubscribe();
  }
}
