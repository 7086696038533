import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';

@Directive({
  selector: '[appPhoneNumberFormat]',
})
export class PhoneNumberFormatDirective {
  constructor(private el: ElementRef, private control: NgControl, private phoneNumberPipe: PhoneNumberPipe) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.phoneNumberPipe.transform(value);
    this.control.control?.setValue(formattedValue, { emitEvent: false });
  }
}
