import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
    constructor(public ngControl: NgControl) { }
    @HostListener('input', ['$event'])
    public onInput(inputEvent: InputEvent): void {
        const event = inputEvent.target as HTMLInputElement;
        this.onInputChange(event.value, false);
    }
    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event: any): void {
        this.onInputChange(event.target.value, true);
    }
    onInputChange(event: any, backspace: any): void {
        if (!event) {
            return;
        }
        if (event.length > 15) {
            return;
        }
        let newVal = event.replace(/\D/g, '');

        if (newVal.length < 10) {
            if (backspace && newVal.length <= 6) {
                newVal = newVal.substring(0, newVal.length - 1);
            }
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length <= 3) {
                newVal = newVal.replace(/^(\d{0,3})/, '($1)');
                if (newVal !== '') {
                    this.ngControl.valueAccessor?.writeValue(newVal);
                    this.ngControl.control?.setValue(newVal);
                }
            } else if (newVal.length <= 6) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
            } else if (newVal.length <= 10) {
                newVal = newVal.replace(
                    /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                    '($1) $2-$3'
                );
            } else {
                newVal = newVal.substring(0, 10);
                newVal = newVal.replace(
                    /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                    '($1) $2-$3'
                );
            }
            if (newVal !== '') {
                this.ngControl.control?.setValue(newVal);
            } else {
                this.ngControl.reset();
            }
        } else {
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,100})/,
                '($1) $2-$3'
            );
            this.ngControl.control?.setValue(newVal);
        }
    }
}
