import { Component, Inject, inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserView } from '../../../graphql/support-console.queries.graphql';
import { MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT } from '../../users/users.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { SmsSetupService } from '../../../services/sms-setup.service';
import { USER_CLAIM_SMS_SERVICE } from 'src/app/core/consts/core-userportal-const';
import { UserClaim, UserSmsClaimValue } from '../../../graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { formatDateTimeToFortifyFormat } from 'src/app/shared/helpers';

@Component({
  selector: 'app-sms-setup',
  templateUrl: './sms-setup.component.html',
  styleUrl: './sms-setup.component.scss'
})
export class SmsSetupComponent implements OnInit, OnChanges {
  private readonly _breakpointObserver = inject(BreakpointObserver);
  private readonly _smsSetupService = inject(SmsSetupService);
  private readonly _dialogRef = inject(MatDialogRef<SmsSetupComponent>);
  private readonly _unsubscribeAll: Subject<void> = new Subject<void>();


  isSummaryVisible = false;
  isStepperEditable = true;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: UserView }) {
  }


  ngOnInit(): void {
    this.initSmsServiceState();
    this.getSmsStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.forceCloseDialog();
  }

  initSmsServiceState(): void {
    this._smsSetupService.isSmsServiceActive.next(false);
    this._smsSetupService.userClaimValue.next(null);
  }

  async getSmsStatus(): Promise<void> {
    const userId = this.data.user.id;
    const userClaimName = USER_CLAIM_SMS_SERVICE;
    try {
      const response: UserClaim = await this._smsSetupService.getUserClaimByName(userId, userClaimName);
      let userClaimValue!: UserSmsClaimValue;

      if (!response) {
        this._smsSetupService.isSmsServiceActive.next(false);
        return
      }

      userClaimValue = JSON.parse(response.claimValue) as UserSmsClaimValue;
      this._smsSetupService.userClaimValue.next(userClaimValue);

      if (!userClaimValue.deActivationDate) {
        this._smsSetupService.isSmsServiceActive.next(false);
        return
      }

      const expirationDate = new Date(userClaimValue.deActivationDate);
      const today = new Date(formatDateTimeToFortifyFormat(0));

      if (expirationDate < today) {
        this._smsSetupService.isSmsServiceActive.next(false);
        return;
      }

      this._smsSetupService.isSmsServiceActive.next(true);

    } catch (error) {
      console.error('Error in method getSmsStatus: ', error);
    }

  }

  forceCloseDialog(): void {
    this._breakpointObserver
      .observe(`(max-width: ${MIN_WIDTH_SUPPORT_CONSOLE_SCREEN_SUPPORT}px)`)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result.matches) {
          this._dialogRef.close(true);
        }
      });
  }

  checkAllStepsCompleted(): void {
    this.isSummaryVisible = true;
  }

  resetProcess() {
    this.isSummaryVisible = false;
  }

  completeUserVerificationStep(response: boolean): void {
    if (!response) {
      return;
    }
    this.stepper.next();
  }
}
