import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client/core';
import { DashboardContentfulPage, GET_DASHBOARD_PAGE, GET_DASHBOARD_PAGE_PROMOTIONAL } from '../graphql/dashboard-data.query';
import { environment } from 'src/environments/environment';
import { GraphqlHostService } from 'src/app/shared/services/graphql-host/graphql-host.service';
import { GET_APP_TILES, GET_PROFILE_INFO, HomePageLinks, HomePageLinksResponse, UserProfileData, UserProfileResponse } from '../graphql/profile-info.query';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { ErrorHandleService } from 'src/app/shared/services/error-handle/error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  applyPolicy!: WatchQueryFetchPolicy;
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  catchRbsErrors: boolean = false;

  constructor(
    private graphqlHost: GraphqlHostService,
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService
  ) {
  }

  /**
   * Fetches the dashboard page data from the Contentful Graphql API.
   *
   * @param policy The fetch policy to apply (cache-first, cache-and-network, etc.).
   * @returns A Promise containing the dashboard page data pulled from Contentful.
   */
  fetchDashboardPage(policy: FetchPolicy, promotionalContent: boolean = false): Promise<DashboardContentfulPage> {
    // Indicate that data is currently being fetched/loading.
    this.isLoadingSubject.next(true);

    // Default fetch policy.
    this.applyPolicy = 'cache-first';

    // If a specific fetch policy is provided, use it.
    if (policy) {
      this.applyPolicy = policy;
    }

    // Optional context for the query.
    const optionalContext = null;

    // Return a Promise that resolves with the fetched data.
    return new Promise((resolve, reject) => {
      // Use the graphqlHost to get query results from the API.
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.contentful,
        GET_DASHBOARD_PAGE_PROMOTIONAL,
        { promotionalContent }, // Pass the filter variable here
        optionalContext,
        this.applyPolicy,
      ).then((response: DashboardContentfulPage) => {
        // Resolve the Promise with the fetched data and mark loading as complete.
        resolve(response);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        // Handle errors and resolve with an error response to avoid potential resolver issues.
        const errorRejected = this.errorHandleService.serverErrorResponse('Contentful', 'fetchDashboardPage', error);
        console.error(errorRejected);
        reject(errorRejected);
      });
    });
  }


  // fetchProfileCardInfo(policy: FetchPolicy): Promise<UserProfileData> {
  //   this.isLoadingSubject.next(true);
  //   this.applyPolicy = 'cache-first';

  //   if (policy) {
  //     this.applyPolicy = policy;
  //   }

  //   const optionalContext = null;

  //   const vars = { id: this.authenticationService.userId.value };

  //   return new Promise((resolve, reject) => {
  //     this.graphqlHost.getQueryResults(
  //       environment.graphqlServerName.rbs,
  //       GET_PROFILE_INFO,
  //       vars,
  //       optionalContext,
  //       this.applyPolicy,
  //     ).then((response: UserProfileResponse) => {
  //       resolve(response.userById);
  //       this.isLoadingSubject.next(false);
  //     }).catch((error: any) => {
  //       const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'fetchProfileCardInfo', error);
  //       console.error(errorRejected);
  //       reject(errorRejected);
  //     })
  //   })
  // }

  fetchAppTiles(policy: FetchPolicy): Promise<HomePageLinks> {
    this.isLoadingSubject.next(true);
    this.applyPolicy = 'cache-first';

    if (policy) {
      this.applyPolicy = policy;
    }

    const optionalContext = null;

    return new Promise((resolve, reject) => {
      this.graphqlHost.getQueryResults(
        environment.graphqlServerName.rbs,
        GET_APP_TILES,
        null,
        optionalContext,
        this.applyPolicy,
      ).then((response: HomePageLinksResponse) => {
        resolve(response.homePageLinks);
        this.isLoadingSubject.next(false);
      }).catch((error: any) => {
        const errorRejected = this.errorHandleService.serverErrorResponse('RBS', 'fetchAppTiles', error);
        console.error(errorRejected);
        reject(errorRejected);
      })
    })
  }
}