import { Component, computed, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, interval, takeWhile, finalize, combineLatest, takeUntil, Subject } from 'rxjs';
import { UserView } from 'src/app/features/support-console/graphql/support-console.queries.graphql';
import { SmsSetupService } from 'src/app/features/support-console/services/sms-setup.service';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';
import { SmsSetupComponent } from '../../sms-setup.component';
import { SmsTwilioService } from 'src/app/features/support-console/services/sms-twilio.service';
import { CreateUserClaimInput } from 'src/app/features/support-console/graphql/mutations/create-claim-by-user-sms.graphql';
import { UserSmsClaimValue } from 'src/app/features/support-console/graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { USER_CLAIM_SMS_SERVICE } from 'src/app/core/consts/core-userportal-const';

@Component({
  selector: 'app-phone-number-verification',
  templateUrl: './phone-number-verification.component.html',
  styleUrls: ['./phone-number-verification.component.scss']
})
export class PhoneNumberVerificationComponent implements OnInit, OnDestroy {
  @Input() user!: UserView;
  @Output() markIdVerficationAsCompleted: EventEmitter<boolean> = new EventEmitter();

  isSmsServiceActive: boolean = false;
  userClaimValue!: UserSmsClaimValue;

  private readonly userPortalDialogService = inject(UserPortalDialogService);
  private readonly smsSetupService = inject(SmsSetupService);
  private readonly smsTwilioService = inject(SmsTwilioService);
  private readonly phoneNumberPipe = inject(PhoneNumberPipe);
  private readonly countDownValue = 30;
  private _dialogRef = inject(MatDialogRef<SmsSetupComponent>);

  private countdownSubject = new BehaviorSubject<number>(this.countDownValue);
  countdown$ = this.countdownSubject.asObservable();
  private countdownSubscription?: Subscription;

  tooltipMessage: string = 'If you only need to update the phone number, click Update and Close. If you need to save and continue with the acknowledgements step, click Next.'
  isCodeSent = false;
  isPhoneVerified = false;
  initialPhoneClaim = '';
  phoneNumberClaimId = '';
  isPhoneNumberUpdated = false;

  phoneFormControl!: FormControl;
  verificationCodeFormControl!: FormControl;
  responseValidationCode: 'success' | 'error' | 'none' = 'none';
  isStepComplete = false;
  countryCode: string = '';
  showCountdown: boolean = false;

  _isSendingCode = this.smsTwilioService._isSendingCode;
  _verifyingCode = this.smsTwilioService._loading;
  _isLoading = this.smsSetupService._loading;
  payloadUserClaim = this.smsSetupService.payloadUserClaim;


  private _destroy$ = new Subject<void>();


  ngOnInit(): void {
    this.phoneFormControl = new FormControl('', { validators: [Validators.required] });
    this.verificationCodeFormControl = new FormControl('', { validators: [Validators.required, Validators.minLength(6)] });
    this.evaluatePhoneNumberClaimIsUpdated();
    this.validatePhoneNumberCountryCode();
    this.verifyUserSmsStatus();
  }

  /**
 * Checks and updates the user's SMS service status and claim details.
 * Automatically unsubscribes upon component destruction to avoid memory leaks.
 * @returns {void}
 */
  verifyUserSmsStatus(): void {
    combineLatest([
      this.smsSetupService.isSmsServiceActive,
      this.smsSetupService.userClaimValue
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([smsStatus, claimValue]) => {
        this.isSmsServiceActive = smsStatus;
        if (claimValue) {
          this.userClaimValue = claimValue;
          this.initialPhoneClaim = this.countryCode === '1'
            ? this.phoneNumberPipe.transform(this.userClaimValue.phoneNumber.substring(1))
            : this.phoneNumberPipe.transform(this.userClaimValue.phoneNumber.substring(3));
          this.phoneFormControl.patchValue(this.initialPhoneClaim);
        }
      });
  }


  /**
 * Sends an SMS verification code to the user's phone number using Twilio service.
 *
 * Workflow:
 * - Validates that the phone number field contains a valid value.
 * - Cleans the phone number input, removing any non-numeric characters, and prefixes it with the appropriate country code.
 * - Sends an SMS verification code using the Twilio SMS service.
 * - On successful SMS sending:
 *   - Initiates a countdown timer to handle resending restrictions.
 *   - Displays a success notification to the user.
 *   - Updates internal state (`isCodeSent`) to reflect the successful sending of the verification code.
 * - Handles errors gracefully, displaying appropriate notifications and logging the error details for debugging purposes.
 *
 * @returns {void}
 */
  sendVerificationCode(): void {
    if (this.phoneFormControl.valid) {
      const phoneNumberOnlyNumbers: string = this.countryCode + this.phoneFormControl.value.replace(/\D/g, '');
      this.smsTwilioService.sendVerificationSmsCode(phoneNumberOnlyNumbers)
        .then((result) => {
          if (result.data) {
            this.startCountdown();
            this.userPortalDialogService.openUserPortalSnackBar('Verification code sent to your phone number');
          } else {
            this.startCountdown(10);
            this.userPortalDialogService.openUserPortalSnackBar('Error: Please make sure the phone number is correct and try again.');
            console.log('Error sending code: ', result.errors.message);
          }

          this.isCodeSent = true;

        }).catch((error) => {
          this.userPortalDialogService.openUserPortalSnackBar('Error sending the code. Please try again later.');
          console.log('Error sending code: ', error);
        })
    }
  }

  /**
 * Verifies the user-provided SMS verification code.
 *
 * Procedure:
 *  - Checks if the verification code entered by the user is valid.
 *  - Formats the user's phone number by concatenating the country code and the entered phone number after removing non-digit characters.
 *  - Calls Twilio's SMS verification service to validate the provided verification code.
 *
 * Outcomes:
 * - On successful verification:
 *   - The user’s verification status is updated internally (`isPhoneVerified`).
 * - On failure (invalid code or service error):
 *   - Logs the error details to the console.
 *   - Sets verification status as unsuccessful.
 *
 * @returns {void}
 */
  verifyPhoneNumber(): void {
    if (this.verificationCodeFormControl.valid) {
      const phoneNumberOnlyNumbers: string = this.countryCode + this.phoneFormControl.value.replace(/\D/g, '');
      this.smsTwilioService.verifyVerificationSmsCode(phoneNumberOnlyNumbers, this.verificationCodeFormControl.value)
        .then((result) => {
          if (result.data) {
            this.responseValidationCode = 'success';
            this.isPhoneVerified = true;
          } else {
            console.log("Error verifying code: ", result.errors.message);
            this.responseValidationCode = 'error';
            this.isPhoneVerified = false;
          }
        }).catch((error) => {
          console.log("Error verifying code: ", error);
          this.responseValidationCode = 'error';
          this.isPhoneVerified = false;
        });
    }
  }


  /**
 * Updates the user's SMS claim value with the new verified phone number.
 *
 * Performs the following steps:
 * - Extracts and formats the phone number from the user's input.
 * - Prepares a payload (`UserSmsClaimValue`)
 *
 * On successful update:
 * - Displays a snackbar message confirming the phone number update.
 * - Optionally closes the dialog if specified by the `closeDialog` flag.
 *
 * Error handling:
 * - Logs any error during the update process and allows for graceful UI feedback.
 *
 * @returns {Promise<void>} Resolves when the user claim is successfully updated.
 */
  async updateUserNumberClaim(closeDialog: boolean): Promise<void> {
    const phoneNumberFormatted = this.phoneFormControl.value.replace(/\D/g, '');
    const claimInput: UserSmsClaimValue = {
      verifiedBy: this.userClaimValue.verifiedBy,
      phoneNumber: this.countryCode + phoneNumberFormatted,
      acknowledgementsAgreed: this.userClaimValue.acknowledgementsAgreed,
      enabled: this.userClaimValue.enabled,
      deActivationDate: this.userClaimValue.deActivationDate // 60 days
    }

    const SMS_USER_CLAIM_INPUT: CreateUserClaimInput = {
      userId: this.user.id,
      claimType: USER_CLAIM_SMS_SERVICE,
      claimValue: JSON.stringify(claimInput),
    };

    try {
      await this.smsSetupService.updateUserClaim(SMS_USER_CLAIM_INPUT);
      this.userPortalDialogService.openUserPortalSnackBar('Phone Number Updated');
      if (closeDialog) {
        this._dialogRef.close(true);
      }
    } catch (error) {
      this.userPortalDialogService.openUserPortalSnackBar('Error saving phone number');
      console.error('Error creating phone number claim: ', error);
    }

  }


  evaluatePhoneNumberClaimIsUpdated(): void {
    this.phoneFormControl.valueChanges.subscribe((value) => {
      if (value !== this.initialPhoneClaim) {
        this.isPhoneNumberUpdated = true;
      } else {
        this.isPhoneNumberUpdated = false;
      }
    });
  }


  startCountdown(countdownForNotFound?: number): void {
    this.showCountdown = true;
    // Prevent multiple subscriptions
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    this.countdownSubject.next(countdownForNotFound ? countdownForNotFound : this.countDownValue); // Reset countdown

    this.countdownSubscription = interval(1000)
      .pipe(
        takeWhile(() => this.countdownSubject.value > 0),
        finalize(() => {
          this.countdownSubject.next(countdownForNotFound ? countdownForNotFound : this.countDownValue);
          this.isCodeSent = false;
          this.showCountdown = false;
        }) // Reset after reaching 0
      )
      .subscribe(() => {
        this.countdownSubject.next(this.countdownSubject.value - 1);
      });
  }

  validatePhoneNumberCountryCode(): void {
    // this.user.userName.includes('cikume') this.user.userName === 'team42-frontend@cikume.com'
    if (this.user.userName.includes('team42-frontend@cikume.com')) {
      this.countryCode = '503';
      this.phoneFormControl.addValidators(Validators.minLength(12));
    } else {
      this.countryCode = '1';
      this.phoneFormControl.addValidators(Validators.minLength(14));
    }
    this.phoneFormControl.updateValueAndValidity();
  }



  finishStep(): void {
    const phoneNumberFormatted = this.phoneFormControl.value.replace(/\D/g, '');
    this.smsSetupService.payloadUserClaim.set({
      acknowledgementsAgreed: this.userClaimValue ? this.userClaimValue.acknowledgementsAgreed : false,
      enabled: this.userClaimValue ? this.userClaimValue.enabled : false,
      phoneNumber: this.countryCode + phoneNumberFormatted,
      deActivationDate: this.userClaimValue ? this.userClaimValue.deActivationDate : '',
      verifiedBy: this.payloadUserClaim()?.verifiedBy ?? ''
    });
    this.markIdVerficationAsCompleted.emit(true);
    this.isStepComplete = true;
  }

  ngOnDestroy(): void {
    this.countdownSubscription?.unsubscribe();
  }
}
