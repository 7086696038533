<div class="dialog-container">
    <div class="dialog-header">
        <h4>{{ isSummaryVisible ? 'SMS Service Successfully Activated' : 'Enable SMS verification code' }}</h4>
    </div>
    <div class="dialog-body">
        @if(!isSummaryVisible) {
        <mat-stepper linear #stepper>
            <!-- SECTION User Verification Step -->
            <mat-step [editable]="isStepperEditable">
                <ng-template matStepLabel>User ID Verification</ng-template>
                <div class="step-body">
                    <app-id-verification 
                    (markIdVerficationAsCompleted)="completeUserVerificationStep($event)"
                     [user]="data.user"
                     ></app-id-verification>
                </div>
            </mat-step>
            <!-- SECTION Phone Number Verification Step -->
            <mat-step [editable]="isStepperEditable">
                <ng-template matStepLabel>Phone Number Verification</ng-template>
                <div class="step-body">
                    <app-phone-number-verification 
                    [user]="data.user"
                    (markIdVerficationAsCompleted)="completeUserVerificationStep($event)"></app-phone-number-verification>
                </div>
            </mat-step>
            <!-- SECTION User Acknowledgements Step -->
            <mat-step [editable]="isStepperEditable">
                <ng-template matStepLabel>User Acknowledgement</ng-template>
                <div class="step-body">
                    <app-user-acknowledgements [user]="data.user" (goToSummary)="checkAllStepsCompleted()"></app-user-acknowledgements>
                </div>
            </mat-step>
        </mat-stepper>
        }
        @else {
            <!-- SECTION SMS setup Summary -->
        <app-sms-setup-summary [user]="data.user"></app-sms-setup-summary>
        }
    </div>
</div>