import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from '../core/materialIo.module';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from './components/button/button.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { ConfirmationBottomSheetComponent } from './components/confirmation-bottom-sheet/confirmation-bottom-sheet.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PortalComponent } from './layouts/portal/portal.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { ErrorHandleComponent } from './components/error-handle/error-handle.component';
import { ContenfultToHtmlPipe } from './pipes/contenfult-to-html.pipe';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { NoMobileSupportComponent } from './components/no-mobile-support-banner/no-mobile-support.component';
import { NoBlankStartDirective } from './directives/no-blank-start.directive';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { NoWhiteSpaceDirective } from './directives/no-white-space.directive';
import { UserServicesAgreementComponent } from './layouts/portal/user-services-agreement/user-services-agreement.component';
import { UserServicesAgreementDialogComponent } from './layouts/portal/user-services-agreement/user-services-agreement-dialog/user-services-agreement-dialog.component';
import { ExtractLicTypePipe } from './pipes/extract-lic-type.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { UserCreatorDialogComponent } from './components/user-creator-dialog/user-creator-dialog.component';
import { ShortTextPipe } from './pipes/short-text.pipe';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';
import { RestrictSpecialCharsDirective } from './directives/restrict-special-chars.directive';
import { DrawerMenuComponent } from './components/drawer-menu/drawer-menu.component';
import { StatusBannerComponent } from './components/status-banner/status-banner.component';
import { PhoneNumberFormatDirective } from './directives/phone-number-format.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';

@NgModule({
  declarations: [
    BottomSheetComponent,
    ButtonComponent,
    ConfirmationBottomSheetComponent,
    ContenfultToHtmlPipe,
    CustomSnackbarComponent,
    CustomDatePipe,
    DialogComponent,
    ErrorHandleComponent,
    FooterComponent,
    HeaderComponent,
    InfoCardComponent,
    NoBlankStartDirective,
    NoMobileSupportComponent,
    NoWhiteSpaceDirective,
    NotificationComponent,
    OnlyNumbersDirective,
    PhoneNumberPipe,
    PortalComponent,
    UnderConstructionComponent,
    UserServicesAgreementComponent,
    UserServicesAgreementDialogComponent,
    ExtractLicTypePipe,
    ReplacePipe,
    UserCreatorDialogComponent,
    ShortTextPipe,
    UserAvatarComponent,
    UserProfileCardComponent,
    RestrictSpecialCharsDirective,
    DrawerMenuComponent,
    StatusBannerComponent,
    PhoneNumberFormatDirective,
    PhoneMaskDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialsModule,
    RouterModule,
    ReactiveFormsModule,
],
  exports: [
    UserAvatarComponent,
    UserProfileCardComponent,
    ButtonComponent,
    CustomDatePipe,
    FooterComponent,
    HeaderComponent,
    InfoCardComponent,
    NoBlankStartDirective,
    NotificationComponent,
    NoMobileSupportComponent,
    ErrorHandleComponent,
    UnderConstructionComponent,
    PhoneNumberPipe,
    OnlyNumbersDirective,
    NoWhiteSpaceDirective,
    ExtractLicTypePipe,
    ReplacePipe,
    ShortTextPipe,
    MaterialsModule,
    RestrictSpecialCharsDirective,
    StatusBannerComponent,
    PhoneNumberFormatDirective,
    PhoneMaskDirective
  ],
  providers: [
    DatePipe,
    PhoneNumberPipe
  ]
})
export class SharedModule { }
