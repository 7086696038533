import { DatePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { UserSmsClaimValue } from 'src/app/features/support-console/graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { UserView } from 'src/app/features/support-console/graphql/support-console.queries.graphql';
import { SmsSetupService } from 'src/app/features/support-console/services/sms-setup.service';
import { formatDateTimeToFortifyFormat } from 'src/app/shared/helpers';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';

@Component({
  selector: 'app-id-verification',
  templateUrl: './id-verification.component.html',
  styleUrl: './id-verification.component.scss'
})
export class IdVerificationComponent implements OnInit, OnDestroy {


  private readonly _smsSetupService = inject(SmsSetupService);
  private readonly _authenticationService = inject(AuthenticationService);
  private readonly _userPortalDialogService = inject(UserPortalDialogService);
  private readonly _pipeDateTimeToLocaleString = inject(DatePipe)


  @Input() user!: UserView;
  isSmsServiceActive: boolean = false;
  userClaimValue!: UserSmsClaimValue;

  @Output() markIdVerficationAsCompleted: EventEmitter<boolean> = new EventEmitter();

  _loadingSignal = this._smsSetupService._loading;
  idVerificationComplete: FormControl = new FormControl(false, { validators: [Validators.required] });
  userIdVerificationComplete: boolean = false;
  isCheckingStatus: boolean = false;

  expirationDate!: string;
  smsServiceBanner!: string;

  private _destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.verifyUserSmsAgreement();
  }

  /**
   * Verifies the user's SMS agreement status by checking if the SMS service is active
   * and retrieving any existing user claim value related to SMS service.
   * Updates component state to reflect SMS service activation, expiration date,
   * and sets an informative banner message accordingly.
   * Automatically unsubscribes upon component destruction to prevent memory leaks.
   *
   * @returns {void}
   */
  verifyUserSmsAgreement(): void {
    this.isCheckingStatus = true;
    combineLatest([
      this._smsSetupService.isSmsServiceActive,
      this._smsSetupService.userClaimValue
    ])
      .pipe(takeUntil(this._destroy$)) // Automatically unsubscribes when component is destroyed
      .subscribe({
        next: ([smsStatus, claimValue]) => {
          this.isSmsServiceActive = smsStatus;
          if (!claimValue) {
            return
          }
          const expirationDate = new Date(claimValue.deActivationDate);
          const currentDate = new Date(formatDateTimeToFortifyFormat(0));

          this.userClaimValue = claimValue;
          if (expirationDate > currentDate) {
            this.expirationDate = this._pipeDateTimeToLocaleString.transform(this.userClaimValue?.deActivationDate, 'longDate')?.toString() ?? '';
            this.smsServiceBanner = `SMS service is already enabled for this user and will expire on ${this.expirationDate}. To update the phone number, verify identity and press 'Next'.`;
          }
          this.isCheckingStatus = false;

        },
        error: (error) => {
          console.error('Error occurred while checking SMS service status', error);
        },
        complete: () => {
          this.isCheckingStatus = false;
        }
      });
  }



  async setIdVerificationCompletedByUser(): Promise<void> {

    if (this.userIdVerificationComplete) {
      this.markIdVerficationAsCompleted.emit(true);
      return;
    }

    // Displays a success message to the user.
    this._userPortalDialogService.openUserPortalSnackBar('User ID verification has been successfully completed for this user.');

    // Updates the verification status payload.
    this._smsSetupService.payloadUserClaim.set({
      acknowledgementsAgreed: this.userClaimValue ? this.userClaimValue.acknowledgementsAgreed : false,
      enabled: this.userClaimValue ? this.userClaimValue.enabled : false,
      phoneNumber: this.userClaimValue ? this.userClaimValue.phoneNumber : '',
      deActivationDate: this.userClaimValue ? this.userClaimValue.deActivationDate : '',
      verifiedBy: this._authenticationService.userName.value
    });

    this.userIdVerificationComplete = true;

    // Emits an event indicating the verification process was successful.
    this.markIdVerficationAsCompleted.emit(true);
  }


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
