import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { USER_CLAIM_TWO_FACTOR_TYPE, NEW_TWO_FACTOR_TYPE_VALUE_SMS, SMS_EXPIRATION_SERVICE_VALUE, USER_CLAIM_SMS_SERVICE } from 'src/app/core/consts/core-userportal-const';
import { CreateUserClaimInput } from 'src/app/features/support-console/graphql/mutations/create-claim-by-user-sms.graphql';
import { UserSmsClaimValue } from 'src/app/features/support-console/graphql/mutations/get-claim-date-expiration-by-user-sms.graphql';
import { UserView } from 'src/app/features/support-console/graphql/support-console.queries.graphql';
import { SmsSetupService } from 'src/app/features/support-console/services/sms-setup.service';
import { formatDateTimeToFortifyFormat, formatDateTimeToLocaleString } from 'src/app/shared/helpers';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';

@Component({
  selector: 'app-user-acknowledgements',
  templateUrl: './user-acknowledgements.component.html',
  styleUrl: './user-acknowledgements.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAcknowledgementsComponent implements OnInit {

  @Input() user!: UserView;
  @Output() goToSummary: EventEmitter<unknown> = new EventEmitter();
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _smsSetupService = inject(SmsSetupService);
  private readonly _userPortalDialogService = inject(UserPortalDialogService);
  loadingData = signal<boolean>(false);

  agreementsForm!: FormGroup;

  isSmsServiceActive: boolean = false;
  userClaimValue!: UserSmsClaimValue;

  payloadUserClaim = this._smsSetupService.payloadUserClaim;

  private _destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.agreementsForm = this._formBuilder.group({
      receiveTextMessages: new FormControl('', [Validators.required]),
      timeLimit: new FormControl('', [Validators.required]),
      useAuthApp: new FormControl('', [Validators.required]),
    });
  }

  verifyUserSmsAgreement(): void {
    combineLatest([
      this._smsSetupService.isSmsServiceActive,
      this._smsSetupService.userClaimValue
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([smsStatus, claimValue]) => {
        this.isSmsServiceActive = smsStatus;
        if (claimValue) {
          this.userClaimValue = claimValue;
        }
      });
  }

  private async handleUserClaims(twoFactorClaims: boolean, smsExpirationClaims: boolean): Promise<void> {
    try {

      const claimOperations: Promise<void>[] = [];
      const payloadUserClaimValue = this.payloadUserClaim();

      if (!payloadUserClaimValue) {
        this._userPortalDialogService.openUserPortalSnackBar('Failed to retrieve payloadUserClaimValue');
        return
      }

      const TWO_FACTOR_USER_CLAIM = {
        userId: this.user.id,
        claimType: USER_CLAIM_TWO_FACTOR_TYPE,
        claimValue: NEW_TWO_FACTOR_TYPE_VALUE_SMS,
      };


      const claimInput: UserSmsClaimValue = {
        verifiedBy: payloadUserClaimValue.verifiedBy,
        phoneNumber: payloadUserClaimValue.phoneNumber,
        acknowledgementsAgreed: true,
        deActivationDate: formatDateTimeToFortifyFormat(SMS_EXPIRATION_SERVICE_VALUE),
        enabled: true,
      }

      const SMS_USER_CLAIM_INPUT: CreateUserClaimInput = {
        userId: this.user.id,
        claimType: USER_CLAIM_SMS_SERVICE,
        claimValue: JSON.stringify(claimInput), // 60 days
      };

      // Handle TwoFactorType claim
      claimOperations.push(
        (twoFactorClaims
          ? this._smsSetupService.updateUserClaim(TWO_FACTOR_USER_CLAIM)
          : this._smsSetupService.createUserClaim(TWO_FACTOR_USER_CLAIM)
        ).then(() => { }) // Ignore return value, enforce Promise<void>
      );

      // Handle SmsServiceExpirationDate claim
      claimOperations.push(
        (smsExpirationClaims
          ? this._smsSetupService.updateUserClaim(SMS_USER_CLAIM_INPUT)
          : this._smsSetupService.createUserClaim(SMS_USER_CLAIM_INPUT)
        ).then(() => { }) // Ignore return value, enforce Promise<void>
      );

      // Execute all claim operations in parallel
      await Promise.all(claimOperations);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Completes the user acknowledgement process and emits an event to navigate to the summary.
   * This function is typically called when the user has finished reviewing and accepting all required acknowledgements.
   * 
   * @returns {void} This function does not return a value.
   * @emits {Object} goToSummary - Emits an empty object to signal the completion of the process.
   */
  completeProcess(): void {
    this.goToSummary.emit();
  }


  async setUpSmsService(): Promise<void> {
    this.loadingData.set(true);
    try {
      // Fetch existing claims in parallel
      const [twoFactorClaims, smsExpirationClaims] = await Promise.all([
        this._smsSetupService.getUserHasClaim(this.user.id, USER_CLAIM_TWO_FACTOR_TYPE),
        this._smsSetupService.getUserHasClaim(this.user.id, USER_CLAIM_SMS_SERVICE),
      ]);

      // Update or create user claims
      await this.handleUserClaims(twoFactorClaims, smsExpirationClaims);

      // Show success message and emit event
      this._userPortalDialogService.openUserPortalSnackBar('SMS Services has activated.');
      this.goToSummary.emit(true);
    } catch (error) {
      this._userPortalDialogService.openUserPortalSnackBar('Something went wrong, please try again later.');
    } finally {
      // Set loading state to false after the process completes
      this.loadingData.set(false);
    }
  }



}