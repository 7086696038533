import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoPasteEvent]',
  standalone: true
})
export class NoPasteEventDirective {

  constructor(private el: ElementRef) { }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');

    if (clipboardData && !/^\d+$/.test(clipboardData)) {
      event.preventDefault();
    }
  }

}
