<div class="phone-registration-container">
  <div class="container-row">
    <div class="indicator">
      <p class="title">Phone Number</p>
      <!-- <p class="description">If this number is not your primary phone number you can update it here.</p> -->
      <p class="description">To update your phone number, enter the new number, request a verification code, and enter the code to confirm. If the code is valid, you will be able to save the update.</p>
    </div>
    <div class="form">
      <form class="example-form">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Phone Number</mat-label>
          <input appPhoneMask type="tel" matInput [formControl]="phoneFormControl" placeholder="(555) 000-0000" maxlength="14" [disabled]="isStepComplete">
          <mat-error *ngIf="phoneFormControl.hasError('minLength')">Please enter a valid phone number</mat-error>
        </mat-form-field>
      </form>
      <div class="actions">
        <button mat-raised-button color="primary" [disabled]="phoneFormControl.invalid || isCodeSent || isStepComplete || _isSendingCode()" 
          (click)="sendVerificationCode()" [class.user-portal-spinner]="_isSendingCode()">Send Code</button>
        @if (showCountdown) { <p class="re-send">
          Please wait <strong>{{countdown$ | async}}</strong> seconds before requesting a new code.
          </p>
        }
      </div>
    </div>
  </div>
  <div class="container-row">
    <div class="indicator">
      <p class="title">Verification Code</p>
      <p class="description">Enter the 6-digit code sent to user's phone number to verify it's correct.</p>
    </div>
    <div class="form">
      <form class="example-form">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Verify Code</mat-label>
          <input appNoPasteEvent only-numbers type="text" matInput [formControl]="verificationCodeFormControl" placeholder="000000" maxlength="6" [disabled]="isStepComplete">
        </mat-form-field>
      </form>
      <div class="actions">
        <button type="button" mat-raised-button color="primary" [disabled]="!verificationCodeFormControl.valid || isStepComplete || isPhoneVerified || _verifyingCode()"
          (click)="verifyPhoneNumber()" [class.user-portal-spinner]="_verifyingCode()">Verify</button>
      </div>
      <div class="mt-1.14">
        @switch (responseValidationCode) {
          @case ('success') {
          <app-status-banner [icon]="'success'" [description]="'Phone verification successful.'"></app-status-banner>
          }
          @case ('error') {
          <app-status-banner [icon]="'error'" [description]="'The code you entered is incorrect. Please try again.'"></app-status-banner>
          }
          @default {
          <span></span>
          }
        }
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="btns-container">
  <button type="button" mat-button matStepperPrevious color="primary">Back</button>

  <div class="flex gap-1">
    <div class="flex items-center">
      <mat-icon color="primary" fontIcon="info_outline" *ngIf="initialPhoneClaim && isPhoneNumberUpdated && isPhoneVerified && !isStepComplete" 
        [matTooltipPosition]="'above'" [matTooltip]="tooltipMessage"></mat-icon>
    </div>
    <button mat-button 
      color="primary" type="button" 
      *ngIf="initialPhoneClaim && isPhoneNumberUpdated && isPhoneVerified && !isStepComplete" 
      [disabled]="!isPhoneVerified || _isLoading()" 
      (click)="updateUserNumberClaim(true)"
      [class.user-portal-spinner]="_isLoading()">
      Update and Close
    </button>
    <button type="button"
      mat-flat-button color="primary" (click)="finishStep()"
      [disabled]="!isPhoneVerified">
      Next
    </button>
  </div>
</div>